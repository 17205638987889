export var dashboardInit = {
    dashboard: {
        init: function (namespace) {
          // Fire charts initialization.
          console.log('Dashboard Init');
          
          namespace.dashboard_charts.init();
  
          // Fire buttons events.
          namespace.buttons_action_events.init();
  
          $('.switch-listing-view__btn').on('click', switchClickCallback);
          function switchClickCallback(event) {
            var $target = $(event.currentTarget),
              $button = $('.switch-listing-view__btn'),
              $wrapper = $('.dashboard_charts'),
              layout = $target.val(),
              active = 'active';
  
            event.preventDefault();
  
            if ($target.hasClass(active)) {
              return !1;
            }
  
            if ($wrapper.hasClass('dashboard_charts--mobile-grid') || $wrapper.hasClass('dashboard_charts--mobile-list')) {
              $wrapper.toggleClass('dashboard_charts--mobile-grid dashboard_charts--mobile-list');
            } else {
              $wrapper.addClass('dashboard_charts--mobile-grid');
            }
  
            $button.toggleClass(active);
            // setGetParameter( 'view', layout );
  
            return !1;
          }
  
          $('.dashboard__filter-btn').on('click', mobileFilterCallback);
          function mobileFilterCallback(event) {
            var $target = $(event.currentTarget),
              $button = $('.dashboard__filter-btn'),
              filterLink = $target.data('mobile-filter-link'),
              active = 'dashboard__filter-btn--active';
  
            event.preventDefault();
  
            $button.removeClass(active);
            $target.addClass(active);
  
            if ('all' === filterLink) {
              $('.dashboard_charts').find('[data-mobile-filterable="true"]').show();
            } else {
              $('.dashboard_charts').find('[data-mobile-filterable="true"]').hide();
              $('.dashboard_charts').find('[data-mobile-filter-value="' + filterLink + '"]').show();
            }
          }
  
          function setGetParameter(paramName, paramValue) {
            var url = window.location.href,
              hash = location.hash,
              prefix, suffix;
  
            url = url.replace(hash, '');
  
            if (url.indexOf(paramName + "=") >= 0) {
  
              prefix = url.substring(0, url.indexOf(paramName));
              suffix = url.substring(url.indexOf(paramName));
              suffix = suffix.substring(suffix.indexOf("=") + 1);
              suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
              url = prefix + paramName + "=" + paramValue + suffix;
  
            } else {
              if (url.indexOf("?") < 0) {
                url += "?" + paramName + "=" + paramValue;
              } else {
                url += "&" + paramName + "=" + paramValue;
              }
            }
  
            // window.location.href = url + hash;
            history.pushState(null, null, url + hash);
          }
  
        }
    } // end dashboard
};