import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Mq from '../util/mq'

export default class SiteNav {
  constructor($el) {
    this.$el = $($el)
    this.activeClass = 'is-nav-open'
    this.scrollArea = '.c-site-nav__inner'

    const $trigger = $('.js-site-nav-trigger')

    $trigger.on('click', e => {
      e.preventDefault()

      if ($('html').hasClass(this.activeClass)) {
        this.closeNav()
      } else {
        this.openNav()
      }
    })

    $(document).on('click', event => {
      const $target = $(event.target)
      if (!$target.closest(this.scrollArea).length && !$target.closest($trigger).length) {
        this.closeNav()
      }
    })

    Mq.atLeast('--site-nav-breakpoint', e => {
      if (e.matches) {
        this.closeNav()
      } 
    })
  }

  openNav() {
    $('html').addClass(this.activeClass)
    disableBodyScroll(this.scrollArea)
  }

  closeNav() {
    $('html').removeClass(this.activeClass)
    enableBodyScroll(this.scrollArea)
  }
}
