//Live search for Add New Project - Select Property

console.log('property search loading');

export var propertySearch = {
    property_search: {
        init: function () {
  
            // Live Search
            var $el = $('#live-search');
            var prevVal = null;
            var timeout = null;
  
            $el.keyup(function (e) {
                var queryInput = $(this).val();
                if (queryInput.length > 3) {
                    clearTimeout(timeout);
                    timeout = setTimeout(function () {
                        $.ajax({
                        url: window.data.ajaxurl,
                        method: 'post',
                        data: {
                            'action': 'hta_filter_propertys',
                            '_ajax_nonce': window.data.nonce,
                            's': queryInput
                        },
                        success: function (response) {
                            console.log(response);
                            var list = $('<div class="o-box u-bg-white c-list-abs u-pt-0"><ul class="c-live-results-list c-list"></ul></div>');
                            if (response.success) {
  
                                $.each(response.data, function (key, value) {
                                    list.append('<li class="c-list__item"><a href="' + value.link + '">' + value.title + '</a></li>');
                                });
  
                                if (!response.data.length) {
                                    list.append('<li class="c-list__item">No results found.</li>');
                                }
  
                                } else {
                                list.append('<li class="c-list__item">No results found.</li>');
                                }
            
                                $('#live-results').html(list);
                            }         
                        });
                    }, 500);
                }
  
                if (queryInput.length < 3) {
                $('#live-results').html('');
                }
  
            });
  
            $el.keypress(function (e) {
                if (e.which == 13) {
                e.preventDefault();
                }
            });
        }
    } // end property_search
};
