// New Project Page
export var newProject = {
    // New Project Page
    new_project: {
        init: function () {
  
          // End Code Form
          $('.js-spinner').hide();
          $('.js-endcode').validate({
            rules: {
              endcode: {
                required: true,
                number: true
              },
              _gotcha: {
                maxlength: 0
              }
            },
            errorClass: 'is-invalid',
            validClass: 'is-valid',
            errorPlacement: function (error, element) {
              error.insertAfter(element.next());
            },
            highlight: function (element, errorClass, validClass) {
              $(element).addClass(errorClass).removeClass(validClass);
              $(element).parent().addClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
              $(element).removeClass(errorClass).addClass(validClass);
              $(element).parent().removeClass(errorClass);
            },
            submitHandler: function (form) {
              var endcode = $('.js-endcode input[name=endcode]').val();
  
              $.ajax({
                url: window.data.ajaxurl,
                method: 'get',
                data: {
                  'action': 'hta_get_property_for_endcode',
                  '_ajax_nonce': window.data.nonce,
                  'endcode': endcode
                },
                beforeSend: function () {
                  $('.js-endcode__message').removeClass('c-alert--error').text('');
                  $('.js-spinner').show();
                },
                success: function (response) {
                  $('.js-spinner').hide();
                  if (response) {
                    // redirect to found property directly
                    window.location = response;
                  } else {
                    $(window).scrollTop(0);
                    $('.js-endcode__message').text('The INN code you have entered was not found. Please try again, or search by different criteria below.').addClass('c-alert--error is-visible');
                  }
                }
              });
            }
          });
  
  
          // Property search form
          $('.js-property-search').validate({
            rules: {
              prop_name: {
                require_from_group: [1, ".js-property-search__input"]
              },
              prop_address: {
                require_from_group: [1, ".js-property-search__input"]
              },
              prop_city: {
                require_from_group: [1, ".js-property-search__input"]
              },
              prop_country: {
                require_from_group: [1, ".js-property-search__input"]
              },
              _gotcha: {
                maxlength: 0
              }
            },
            errorClass: 'is-invalid',
            validClass: 'is-valid',
            errorPlacement: function (error, element) {
              error.insertAfter(element.next());
            },
            highlight: function (element, errorClass, validClass) {
              $(element).addClass(errorClass).removeClass(validClass);
              $(element).parent().addClass(errorClass);
            },
            unhighlight: function (element, errorClass, validClass) {
              $(element).removeClass(errorClass).addClass(validClass);
              $(element).parent().removeClass(errorClass);
            }
          });
        }
    } // end new_project
};