export default class FilterInput {
  constructor($el) {
    this.$el = $($el)
    const $input = $('.js-filtered-input');
    //const $results = $('.js-filtered-results');

    //TODO - search term highlighting in results
    //const openTag = '<span style="color:#00a3dc;">';
    //const closeTag = '</span>';

    $input.on('keyup', (e) => {
        var value = $(e.currentTarget).val().toLowerCase();

        // what we'll wrap matching search terms with
        
        $('.js-filtered-item').each( (_index, element) => {
            
          // clean up any tags from previous search and set up lowercase html we'll work with
          //var origHTML = $(this).html().replace(openTag, '').replace(closeTag, '');
          //$(this).find('.search-data').html(origHTML);
          //var lowerHTML = origHTML.toLowerCase();

          var searchData = $(element).data('search').toLowerCase();

          if (searchData.search(value) > -1) {
            if (value !== '') {

              var regex = new RegExp(value, 'g');
              var indices = [];
              var myArray;
              while ((myArray = regex.exec(searchData)) !== null) {
                indices.push(myArray.index);
              }

              //var searchLen = value.length;
              //var finalHTML = '';

              // reverse is currently unnecessary but might be needed for later
              // if highlighting more than one part of the result makes sense
              //indices.reverse().forEach(function (foundIndex) {

                //finalHTML = origHTML.slice(0, foundIndex) +

                  // wrap our element of interest
                  //openTag + origHTML.slice(foundIndex, foundIndex + searchLen) + closeTag +

                  //origHTML.slice(foundIndex + searchLen);
              //});

              // replace the old with the new
              //$(this).find('.search-data').html(finalHTML);
            }

            $(element).show();
          } else {
            $(element).hide();
          }
        });
      });
    }
}
