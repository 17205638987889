console.log('old main loading');
export var oldMain = {
    // All pages
    common: {
      init: function () {
        console.log('Common Init');

        //Login button animation
        // $('.c-login-form__item--submit #wp-submit1').on('click',function(){ $(this).parent('.c-login-form__item--submit').addClass('is-animating'); });

//VALIDATION NOT KNOWN IF NEEDED
        // Form validator function for requiring a number of fields be filled in.
        $.validator.addMethod("require_from_group", function (value, element, options) {
          console.log('We are really loading the validator method');
          var $fields = $(options[1], element.form),
            $fieldsFirst = $fields.eq(0),
            validator = $fieldsFirst.data("valid_req_grp") ? $fieldsFirst.data("valid_req_grp") : $.extend({}, this),
            isValid = $fields.filter(function () {
              return validator.elementValue(this);
            }).length >= options[0];

          // Store the cloned validator for future validation
          $fieldsFirst.data("valid_req_grp", validator);

          // If element isn't being validated, run each require_from_group field's validation rules
          if (!$(element).data("being_validated")) {
            $fields.data("being_validated", true);
            $fields.each(function () {
              validator.element(this);
            });
            $fields.data("being_validated", false);
          }
          return isValid;
        }, $.validator.format("Please fill at least {0} of these fields."));


        // alias email to cEmail with new message
        $.validator.addMethod("cEmail", $.validator.methods.email, "Please enter a valid email.");
        // combine them both, including the parameter for minlength
        $.validator.addClassRules("js-is-email", { cEmail: true });


        // Event handler function for textarea
        // https://codepen.io/vsync/pen/czgrf
        function autosize() {
          var el = this;
          setTimeout(function () {
            el.style.cssText = 'height:auto; padding:0';
            // for box-sizing other than "content-box" use:
            el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + el.scrollHeight + 'px';
          }, 0);
        }

        // Funtion that parses the url variables and return an array with teh variables
        function getUrlVars() {
          var vars = [],
            hash;
          if (window.location.href.indexOf('?') > 0) {
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
              hash = hashes[i].split('=');
              vars[hash[0]] = hash[1];
            }
          }
          return vars;
        }
        //likely not needed
        // function to set the active tab using depending on the url variables
        function setUrlTab() {
          var urlVars = getUrlVars(),tabId;

          if ((typeof (urlVars.tab) !== 'undefined') && (urlVars.tab !== '')) {
            tabId = urlVars.tab;

            //make that tab active and the menu item
            setActiveTab(tabId);
          }
        }
        //likely not needed
        // function to set active tab and (optional) update the navigation
        function setActiveTab(tabId, setNavigation) {
          setNavigation = (typeof (setNavigation) !== 'undefined') ? setNavigation : true;
          var defaultTabName = $('.js-tab-default').data('tab');
          tabId = (typeof (tabId) !== 'undefined') ? tabId : defaultTabName;

          var $activeTab = $('.js-tab[data-tab=' + tabId + ']');
          if ($activeTab.length) {
            $('.js-tab').removeClass('is-active');
            $activeTab.addClass('is-active');

            //make the corresponding menu active if necessary
            if (tabId === 'end') {
              // hide the menu
              $('.js-header').hide();
            } else if (setNavigation === true) {
              $('.js-tab-link').removeClass('is-active');
              var menuId = $activeTab.data('menu');
              $('.js-tab-link[data-name=' + menuId + ']').addClass('is-active');
            }

            // update the textareas heights in the current tab
            $activeTab.find('textarea').each(function () {
              var textarea = document.getElementById($(this).attr('id'));
              //autosize.apply(textarea);
            });

            // set the correct bg image
            setBackgrounds();

            // scroll the prototypes container
            if (tabId === 'concepts') {
              scrollPrototypesSlider();
            }
          }
          // else maybe change the url to tab=property
        }

        //likely not needed
        // function that updates the url with teh current tab
        function updateUrlTab(tabId) {
          //build url
          var new_url = '';
          new_url += window.location.protocol + "//" + window.location.host + window.location.pathname;

          var urlVars = getUrlVars();
          urlVars.tab = tabId;
          var i = 0;
          for (var k in urlVars) {
            if (typeof (urlVars[k]) !== 'function') {
              if (i === 0) {
                new_url += '?' + k + '=' + urlVars[k];
              } else {
                new_url += '&' + k + '=' + urlVars[k];
              }
              i++;
            }
          }

          // update window url
          // state object, title, url
          window.history.pushState({ tab: tabId }, 'Project ' + tabId, new_url);
        }

        //likely not needed
        // function that sets the text of the dropdown depending on the selected items
        // and adds the has-value class when it's not empty
        function setDropdwnValue($dropdown) {
          var values = [];
          $dropdown.find('.js-dropdown__item:checked').each(function () {
            values.push($(this).next('label').text());
          });
          var values_string = values.join(', ');
          $dropdown.find('.js-dropdown__value').text(values_string);

          if (values_string !== '') {
            $dropdown.addClass('has-value');
          } else {
            $dropdown.removeClass('has-value');
          }
        }

        //seems like this has been replaced with hard coded functionality - need to check

        // function that applies changes to the child dropdown for a given parent dropdown
        // it hides / shows options based on the current parent selection,
        // updates the dropdown text,
        // unselects items that are not options for the current parent selection
        function changeChildForParent($parent) {
          var type = $parent.data('connected');
          var $child = $('.js-connected-child[data-connected=' + type + ']');
          if (!$parent.hasClass('has-value')) {
            // make sure no child value is selected
            $child.find('.js-dropdown__item').attr('checked', false);
            setDropdwnValue($child);
            // disable the child
            $child.attr('disabled', true);
          } else {
            // show only parent's children
            // and uncheck the items that are now hidden
            $child.attr('disabled', false);

            // show only the child options for the parent selection
            $child.find('.js-connected-item').addClass('u-hidden');
            var ids = [];
            $parent.find('.js-dropdown__item:checked').each(function () {
              ids.push($(this).attr('id'));
            });
            ids.forEach(function (item) {
              $child.find('.js-connected-item[data-parent-id=' + item + ']').removeClass('u-hidden');
            });
            // unckeck all the items that are hidden = that are not options for the current parent selection
            $child.find('.js-connected-item.u-hidden input').attr('checked', false);
            // reset the dropdown text value
            $child.find('.js-dropdown__value').text('');
            setDropdwnValue($child);
          }
        }

        // tests if is mobile breakpoint
        function testMobile() {
          if ($(window).width() < 980) {
            return true;
          } else {
            return false;
          }
        }
        //likely not needed
        // sets teh background image for elements
        function setBackgrounds() {
          var sufix = (testMobile()) ? '-m' : '';
          // set all the background images but not for tabs
          $('.js-background:not(.js-in-tab)').each(function () {
            var $url = $(this).data('bg' + sufix);
            $(this).css('background-image', 'url(' + $url + ')');

          });
          // set the background for the current tab
          $('.js-tab.is-active .js-background').each(function () {
            var $url = $(this).data('bg' + sufix);
            $(this).css('backgroundImage', 'url(' + $url + ')');
          });
        }
        //likely not needed
        // scroll prototypes slider to first prototype with details
        function scrollPrototypesSlider() {
          var $slider = $('.js-prototypes-slider');
          if ($slider.length) {
            $slider.scrollLeft(0);
            var $scrollTarget = $slider.find('.js-has-details').first();
            var left = $scrollTarget.offset().left - $slider.offset().left;
            $slider.scrollLeft(left);
          }
        }
        //move to reporting/dashboard or own js file?
//REPORTING AND DASHBOARDS SELECTS
        if ($('body').hasClass('reporting')
          || $('body').hasClass('dashboard')
        ) {

          if ($('.js-SlectBox').hasClass('dashboard__panel_dropdown')) {
            $('.js-SlectBox').SumoSelect();

          } else {
            $('#prop_country.js-SlectBox').SumoSelect({
              search: true,
              nativeOnDevice: []
            });

            $('.js-SlectBox').SumoSelect({
              nativeOnDevice: []
            });
          }

          $('.SumoSelect .options li').on('click',
          function (e) {
            var $select = $(this).closest('.SumoSelect').find('.js-SlectBox');

            e.preventDefault();

            if (!$select.find('option').eq(0).hasClass('default')) {
              return;
            }

            if ($(this).hasClass('default')) {
              $select[0].sumo.unSelectAll();
              $select[0].sumo.selectItem(0);

              $(this).closest('.options').find('li:not(.default)').removeClass('selected');
            } else {
              $select[0].sumo.unSelectItem(0);
            }
          });


        } else {
          $('select:not(.js-SlectBox)').select2({
            minimumResultsForSearch: 20,
            width: '100%'
          }).on('change', function (e) {

            if ($(this).hasClass('is-invalid')) {
              $(this).parent().find('label.is-invalid').remove();
            }

          });
        }
//END REPORTING AND DADHBOARDS SELECT

//MENUS NOT NEEDED - BUT FOR NOTES????
        //'use strict';

        /**
         * Extend Object helper function.
         */
        function extend(a, b) {
          for (var key in b) {
            if (b.hasOwnProperty(key)) {
              a[key] = b[key];
            }
          }
          return a;
        }

        /**
         * Each helper function.
         */
        function each(collection, callback) {
          for (var i = 0; i < collection.length; i++) {
            var item = collection[i];
            callback(item);
          }
        }

        /**
         * Menu Constructor.
         */
        function Menu(options) {
          this.options = extend({}, this.options);
          extend(this.options, options);
          this._init();
        }

        /**
         * Menu Options.
         */
        Menu.prototype.options = {
          wrapper: '#o-wrapper',          // The content wrapper
          type: 'push-left',             // The menu type
          menuOpenerClass: '.c-menu-button',   // The menu opener class names (i.e. the buttons)
          maskId: '#c-mask'               // The ID of the mask
        };

        /**
         * Initialise Menu.
         */
        Menu.prototype._init = function () {
          this.body = document.body;
          this.wrapper = document.querySelector(this.options.wrapper);
          this.mask = document.querySelector(this.options.maskId);
          this.menu = document.querySelector('#c-menu--' + this.options.type);
          this.closeBtn = this.menu.querySelector('.c-menu__close');
          this.menuOpeners = document.querySelectorAll(this.options.menuOpenerClass);
          this._initEvents();
        };

        /**
         * Initialise Menu Events.
         */
        Menu.prototype._initEvents = function () {
          // Event for clicks on the close button inside the menu.
          this.closeBtn.addEventListener('click', function (e) {
            e.preventDefault();
            this.close();
          }.bind(this));

          // Event for clicks on the mask.
          this.mask.addEventListener('click', function (e) {
            e.preventDefault();
            this.close();
          }.bind(this));
        };

        /**
         * Open Menu.
         */
        Menu.prototype.open = function () {
          this.body.classList.add('has-active-menu');
          this.wrapper.classList.add('has-' + this.options.type);
          this.menu.classList.add('is-active');
          this.mask.classList.add('is-active');
          this.disableMenuOpeners();
        };

        /**
         * Close Menu.
         */
        Menu.prototype.close = function () {
          this.body.classList.remove('has-active-menu');
          this.wrapper.classList.remove('has-' + this.options.type);
          this.menu.classList.remove('is-active');
          this.mask.classList.remove('is-active');
          this.enableMenuOpeners();
        };

        /**
         * Disable Menu Openers.
         */
        Menu.prototype.disableMenuOpeners = function () {
          each(this.menuOpeners, function (item) {
            item.disabled = true;
          });
        };

        /**
         * Enable Menu Openers.
         */
        Menu.prototype.enableMenuOpeners = function () {
          each(this.menuOpeners, function (item) {
            item.disabled = false;
          });
        };

        /**
         * Add to global
         */
        window.Menu = Menu;


        /**
         * Push left instantiation and action.
         */

         //kill menus

        if ($('body').hasClass('logged-in') && $('.c-menu-button').length > 0) {
          var pushLeft = new Menu({
            wrapper: '#o-wrapper',
            type: 'push-left',
            menuOpenerClass: '.c-menu-button',
            noteId: '#c-mask'
          });


          $('.c-menu-button').on('click', function (e) {
            e.preventDefault;
            if ($('body').hasClass('has-active-menu')) {
              pushLeft.close();
              $('.c-menu__close').removeClass('open');
            } else {
              pushLeft.open();
              $('.c-menu__close').addClass('open');
            }
          });
        }
//NOTES START
        if ($('body').hasClass('has-notes-menu') && $('.drawer-tab').length > 0) {
          var pushRight = new Menu({
            wrapper: '#o-wrapper',
            type: 'push-right',
            menuOpenerClass: '.drawer-tab',
            noteId: '#c-mask'
          });

          $('.drawer-tab').on('click', function (e) {
            e.preventDefault();

            if ($('body').hasClass('has-active-menu')) {
              pushRight.close();
              $('.c-menu__close').removeClass('open');
            } else {
              pushRight.open();
              $('.c-menu__close').addClass('open');
            }

          });
        }


        if ($('#note_form').length) {

          // Note form submit.
          $('#note_form').submit(function (e) {

            // updates local time at submission
            $('input#current_time').val(new Date().toLocaleString(undefined, { hour12: false }).slice(0, 19).replace('T', ' '));

            var $titleInput = $('#note_input'),
              title = $titleInput.val();

            if (title.length === 0) {
              e.preventDefault();

              $titleInput.attr('placeholder', 'Please enter a title');
            }
          });

          // Notes tab init.
          var $notesTabs = $('#notes-tabs'),
            $activeTab = $notesTabs.find('li.active');

          $notesTabs.tabs({
            active: $activeTab.data('tab-index') - 1
          });

          $('#concept-notes').on('change', function (e) {
            var selected = $(this).val(),
              index = e.currentTarget.selectedIndex;

            e.preventDefault();

            $notesTabs.tabs('option', 'active', index);
          });

          // Note category select.
          $('#note_category').on('change', function (e) {
            e.preventDefault();

            if ('Concepts' === $(this).val()) {
              $('.set-concept-notes').removeClass('u-hidden');
            } else {
              $('.set-concept-notes').addClass('u-hidden');
            }
          });

          // Note concept select.
          $('#hta-get-concept-notes').on('change', function (e) {
            var selected_concept = $(this).val();

            e.preventDefault();

            if (-1 == selected_concept) {
              $('#notes-tab-2 .note__item').removeClass('note__item--hide');
              return !1;
            }

            $('#notes-tab-2 .note__item').addClass('note__item--hide');
            $('#notes-tab-2').find("[data-concept-id='" + selected_concept + "']").removeClass('note__item--hide');
          });

        } // end if



        if ($('.inputfile').length) {

          $('.inputfile').each(function () {
            var $input = $(this),
              $label = $input.next('label'),
              labelVal = $label.html();

            $input.on('change', function (e) {

              var fileName = '';

              if (this.files && this.files.length > 1) {
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
              } else if (e.target.value) {
                fileName = e.target.value.split('\\').pop();
              }

              if (fileName) {
                $label.find('span').html(fileName);
              } else {
                $label.html(labelVal);
              }
            });

            // Firefox bug fix
            $input.on('focus', function () {
              $input.addClass('has-focus');
            })
              .on('blur', function () {
                $input.removeClass('has-focus');
              });
          });

        }
//NOTES END
// END MENUS
        window.show_message = function(msg, type = 'success') {
          console.log('showing an alert note');
          var $alert = $('<div>').addClass('c-alert c-alert--' + type + ' c-alert--note')
            .text(msg)
            .css('z-index', '102');

          $('.c-main').prepend($alert);

          setTimeout(function () {
            $alert.hide('slow', function () {
              $alert.remove();
            });
          }, 4000);
        }

        // console.log('common');
//FORM FIELDS START
        $('.js-form-field').each(function () {
          if ($(this).val() !== '') {
            $(this).addClass('has-value');
          } else {
            $(this).removeClass('has-value');
          }
        });

        $('body').on('blur', '.js-form-field:not(.js-datepicker)', function () {
          if ($(this).val() !== '') {
            $(this).addClass('has-value');
          } else {
            $(this).removeClass('has-value');
          }
        });

        // auto resize textarea
        // https://codepen.io/vsync/pen/czgrf
        $('textarea').each(function () {
          //autosize.apply(this);
        });

        document.querySelector('body').addEventListener('keyup', function (event) {
          if (event.target.tagName.toLowerCase() === 'textarea') {
            //autosize.apply(event.target);
          }
        });

        $('.js-only-number-field').each(function () {
          var $this = $(this);

          $this.keydown(function (e) {

            // Allow: backspace, delete, tab, escape and enter
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
              // Allow: Ctrl/cmd+A
              (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
              // Allow: Ctrl/cmd+C
              (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
              // Allow: Ctrl/cmd+X
              (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
              // Allow: home, end, left, right
              (e.keyCode >= 35 && e.keyCode <= 39)) {
              // let it happen, don't do anything
              return;
            }

            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
              e.preventDefault();
            }
          });

        });

        /* dropdowns *****************************************/
        // open / close
        $('form').on('click', '.js-dropdown', function (e) {
          e.stopPropagation();
          if ($(this).hasClass('is-open')) {
            $(this).removeClass('is-open');
          } else {
            // close other dropdowns
            $('.js-dropdown.is-open').removeClass('is-open');
            $(this).addClass('is-open');
          }
        });
        // prvent closing when clicking inside
        $('form').on('click', '.js-dropdown__items', function (e) {
          e.stopPropagation();
        });
        // add value in dropdown label
        $('form').on('click', '.js-dropdown__item', function (e) {
          console.log('closing a dropdown item');
          var $dropdown = $(this).closest('.js-dropdown');
          setDropdwnValue($dropdown);

          // do extra operations
          if ($dropdown.hasClass('js-connected-parent')) {
            changeChildForParent($dropdown);
          }
        });
        // close on document click
        $(document).on('click', function () {
          $('.js-dropdown.is-open').removeClass('is-open');
        });

        /* Connected dropdowns ---------------- */
        $('.js-connected-parent').each(function () {
          var $parent = $(this);
          changeChildForParent($parent);
        });
//END FORM FIELDS
        /* tabs *****************************************/
        setUrlTab();

        $('body').on('click', '.js-tab-link', function (e) {
          e.preventDefault();

          // change the navigation
          $('.js-tab-link').removeClass('is-active');
          $(this).addClass('is-active');

          // set the active tab
          var tabId = $(this).data('name');
          setActiveTab(tabId, false);

          // change the url
          updateUrlTab(tabId);
        });

        //unknown if needed
        /* side panels *****************************************/
        var ycoord = 0;

        $('.js-panel-close').on('click', function (e) {
          e.preventDefault();
          $('.js-panel').removeClass('is-active');
          $('body').css('position', '').css('left', 'auto').css('right', 'auto').css('top', 'auto');
          $(window).scrollTop(-1 * ycoord);
        });

        $('.js-panel-trigger').on('click', function (e) {
          e.preventDefault();

          // get the target panel
          var targetId = $(this).data('panel-id');
          var $panel = $('.js-panel[data-id=' + targetId + ']');

          // Prevent scrolling when navigation is open
          if (!$panel.hasClass('is-active')) {
            ycoord = $(window).scrollTop();
            ycoord = ycoord * -1;
            $('body').css('position', 'fixed').css('left', '0px').css('right', '0px').css('top', ycoord + 'px');
          } else {
            $('body').css('position', '').css('left', 'auto').css('right', 'auto').css('top', 'auto');
            $(window).scrollTop(-1 * ycoord);
          }

          $panel.addClass('is-active');
        });

        //this definitely needs to be moved
        /* datepicker *****************************************/
        var today = new Date();
        var zeoizedNumbers = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
        var day = zeoizedNumbers[today.getDate() - 1];
        var month = zeoizedNumbers[today.getMonth()];
        var year = today.getFullYear();
        var minDate = new Date();//year + '-' + month + '-' + day;
        if ($('[type="date"]').prop('type') !== 'date') {
          $('[type="date"]').each(function () {
            // set the readonly attribute
            $(this).attr('readonly', true);
            // set the value
            $(this).attr('value', $(this).data('value-js'));

            // set the format option
            var dataFormat = $(this).data('format');
            var format = (typeof (dataFormat) !== 'undefined') ? dataFormat : 'mm/dd/yy';
            // set minimum date option
            // the date fields will be in the future by default
            var minDate = new Date();
            var dateAfter = $(this).data('after');
            if (dateAfter === 'any') {
              // minDate = new Date('1990');
            }
            $(this).datepicker({
              dateFormat: format,
              minDate: minDate,
              changeYear: true,

              beforeShow: function (input, element) {
                $(input).addClass('has-value');
              },
              onClose: function (date, element) {
                if (date !== '') {
                  $('#' + element.id).addClass('has-value');
                } else {
                  $('#' + element.id).removeClass('has-value');
                }
              }
            });
          });
        } else {
          $('[type="date"]').addClass('is-supported');
          // set the minimum date
          // the date fields will be in the future by default
          $('[type="date"]').each(function () {
            if (typeof ($(this).data('date-min')) === 'undefined') {
              // always in the future
              $(this).attr('min', minDate);
            } else {
              if ($(this).data('date-min') === 'any') {
                // don't set a min attribute
              } else {
                $(this).attr('min', $(this).data('date-min'));
              }
            }
          });
        }

        if ($('[type="month"]').prop('type') !== 'month') {
          $('[type="month"]').each(function () {
            // set the readonly attribute
            $(this).attr('readonly', true);
            // set the value
            $(this).attr('value', $(this).data('value-js'));

            // set the format option
            var dataFormat = $(this).data('format');
            var format = (typeof (dataFormat) !== 'undefined') ? dataFormat : 'mm/yy';
            // set minimum date option
            var minDate = new Date();
            var dateAfter = $(this).data('after');
            if (dateAfter === 'any') {
              minDate = new Date('1990');
            }
            $(this).datepicker({
              dateFormat: format,
              minDate: minDate,
              beforeShow: function (input, element) {
                $(input).addClass('has-value');
              },
              onClose: function (date, element) {
                if (date !== '') {
                  $('#' + element.id).addClass('has-value');
                } else {
                  $('#' + element.id).removeClass('has-value');
                }
              }
            });
          });
        } else {
          $('[type="month"]').addClass('is-supported');
          // set the minimum date
          // the date fields will be in the future by default
          minDate = year + '-' + month;
          $('[type="month"]').each(function () {
            if (typeof ($(this).data('month-min')) === 'undefined') {
              // always in the future
              $(this).attr('min', minDate);
            } else {
              if ($(this).data('month-min') === 'any') {
                // don't set a min attribute
              } else {
                $(this).attr('min', $(this).data('month-min'));
              }
            }
          });
        }

        //this definitely needs to be moved
        /* Text Filtering *****************************************/
        $('.js-filter-input').on('keyup', function () {
          var value = $(this).val().toLowerCase();

          // what we'll wrap matching search terms with
          var openTag = '<span style="color:#00a3dc;">';
          var closeTag = '</span>';


          $('.js-filtered-item').each(function () {

            // clean up any tags from previous search and set up lowercase html we'll work with
            var origHTML = $(this).find('.search-data').html().replace(openTag, '').replace(closeTag, '');
            $(this).find('.search-data').html(origHTML);
            var lowerHTML = origHTML.toLowerCase();

            if (lowerHTML.search(value) > -1) {
              if (value !== '') {

                var regex = new RegExp(value, 'g');
                var indices = [];
                var myArray;
                while ((myArray = regex.exec(lowerHTML)) !== null) {
                  indices.push(myArray.index);
                }

                var searchLen = value.length;
                var finalHTML = '';

                // reverse is currently unnecessary but might be needed for later
                // if highlighting more than one part of the result makes sense
                indices.reverse().forEach(function (foundIndex) {

                  finalHTML = origHTML.slice(0, foundIndex) +

                    // wrap our element of interest
                    openTag + origHTML.slice(foundIndex, foundIndex + searchLen) + closeTag +

                    origHTML.slice(foundIndex + searchLen);
                });

                // replace the old with the new
                $(this).find('.search-data').html(finalHTML);
              }

              $(this).show();
            } else {
              $(this).hide();
            }
          });
        });

        /* Add Form Input *****************************************/
        $('form').on('click', '.js-add-row', function (e) {
          e.preventDefault();
          var $newInput = $(this).parent().find('.js-group-row').first().clone();
          var $lastInput = $(this).parent().find('.js-group-row').last();

          // set the data for the new input
          var index = $(this).data('index');
          $(this).data('index', index + 1);

          var input_name = $newInput.find('input').attr('name') + '_' + index;
          $newInput.find('input').attr('name', input_name).attr('id', input_name).val('');

          var label_name = $newInput.find('label').text() + ' ' + (index + 1);
          $newInput.find('label').attr('for', input_name).text(label_name);

          $newInput.insertAfter($lastInput);
          $newInput.find('input').focus();
        });

        /* Background Imags *****************************************/
        setBackgrounds();

        /************************************************************/
        // window popstate - clicking the browser back button
        /************************************************************/
        window.onpopstate = function (e) {
          // set the curent tab
          setUrlTab();
        };

        /************************************************************/
        // window resize debounced -> smartresize
        /************************************************************/
        $(window).smartresize(function () {
          // change teh background images
          setBackgrounds();
        });

        //copy to own file
        // Datapicker init
        window.escapeSelector = function (str) {
          return str.replace(
              /([$%&()*+,./:;<=>?@\[\\\]^\{|}~])/g,
              '\\$1'
          );
        };

        $('input.datapicker').each(function (index, element) {
          var $el = $(element),
            placeholder = $el.data('placeholder'),
            displayFormat = $el.data('format'),
            closeText = $el.data('close-text'),
            availableDays = $el.data('available-days'),
            yearChanged = false,
            dateSelected = false,
            valueFormat = 'yy-mm-dd',
            clone;

          if (typeof displayFormat === 'undefined') {
            displayFormat = 'mm.dd.yy';
          }

          if (typeof placeholder === 'undefined') {
            placeholder = displayFormat;
          }

          /* Create a hidden clone, which will contain the actual value */
          clone = $el.clone();
          clone.insertAfter(this);
          clone.hide();

          /* Rename the original field, used to contain the display value */
          var id = $el.attr('id') ? $el.attr('id') : index;
          var name = $el.attr('name') ? $el.attr('name') : index;

          $el.attr('id', id + '-display');
          $el.attr('name', name + '-display');
          clone.attr('id', id);
          clone.attr('name', name);

          /* Create the datepicker with the desired display format and alt field */
          $el.datepicker({
            dateFormat: displayFormat,
            altField: window.escapeSelector('#' + id),
            altFormat: valueFormat,
            changeMonth: true,
            changeYear: true,
            showOn: 'focus',
            showButtonPanel: true,
            closeText: closeText, // Text to show for "close" button
            beforeShowDay: enableAllTheseDays,
            beforeShow: function (input, inst) {
              // console.log('beforeShow');
              var currentDate = new Date(clone.val()),
                currentDay = currentDate.getDate(),
                currentMonth = currentDate.getMonth() + 1,
                currentYear = currentDate.getFullYear();

              if (typeof availableDays === 'undefined') {
                $el.datepicker('option', 'defaultDate', new Date(clone.val()));

                // if ( 1 === day && 1 === month ) {
                //   $el.datepicker( 'option', 'dateFormat', 'yy' );
                // }

                $el.datepicker('setDate', currentDate);
                yearChanged = false;
              }
            },
            onClose: function (dateText, inst) {
              // console.log('onClose');

              var event = arguments.callee.caller.caller.arguments[0],
                $target = inst.input,
                container_id = $target.data('container'),
                $dashboard = $(container_id).find('.saved-box__item'),
                delegateTarget = $(event.delegateTarget);

              if (delegateTarget.hasClass('ui-datepicker-close')) {
                var buttonText = $.trim(delegateTarget.text());

                // If "Clear" gets clicked, then really clear it
                if ('Clear' === buttonText) {
                  $el.val('');
                  clone.val('');

                  if (!$dashboard.length) {
                    return !1;
                  }

                  $dashboard.show();
                  return !1;
                }
              }

              if (true === yearChanged) {

                if (typeof availableDays === 'undefined') {
                  var year = $('#ui-datepicker-div .ui-datepicker-year :selected').val();

                  $el.datepicker('option', 'dateFormat', 'yy');
                  $el.datepicker('setDate', new Date(year, 0, 1));
                }

              } else {

                if (true === dateSelected) { // if uncommented a lines bellow - bug on FireFix and Safari - auto-selected a current date

                  // $el.datepicker( 'option', 'dateFormat', displayFormat );
                  // $el.datepicker( 'setDate', new Date( dateText ) );
                }
              }
            },
            onChangeMonthYear: function (year, month, inst) {
              var _month = month - 1;

              // Skip setted only year on report and export pages.
              if ($('body').hasClass('reporting') || $('body').hasClass('single-export')) {
                return !1;
              }

              if (inst.currentYear !== year && _month === inst.currentMonth) {
                yearChanged = true;
              } else {
                yearChanged = false;
              }
            },
            onSelect: function (dateText, inst) {
              // console.log('onSelect');

              var $target = inst.input,
                container_id = $target.data('container'),
                filter = dateText,
                $dashboard = $(container_id).find('.saved-box__item');

              dateSelected = true;
              yearChanged = false;
              $el.datepicker('option', 'dateFormat', displayFormat);

              if (!$dashboard.length) {
                return !1;
              }

              $dashboard.each(function (i, element) {
                var $element = $(element);
                date = $element.data('post-date');

                if ('-1' == filter) {
                  $element.show();
                  return;
                }

                if (filter === date) {
                  $element.show();
                } else {
                  $element.hide();
                }

              });
            }
          }).attr({
            'readonly': 'readonly',
            'placeholder': placeholder
          });

          var enableDaysObj = $el.data('available-days'),
            enableDaysArray = (typeof enableDaysObj === 'object') ? Object.values(enableDaysObj) : false;

          function enableAllTheseDays(date) {
            if (typeof enableDaysObj !== 'object') {
              return [true];
            }

            var sdate = $.datepicker.formatDate(valueFormat, date);

            if ($.inArray(sdate, enableDaysArray) != -1) {
              return [true];
            }

            return [false];
          }

          $('.ui-datepicker-current').css('display', 'none');

          /* Finally, parse the value and change it to the display format */
          if ($el.attr('value')) {
            var date = $.datepicker.parseDate(valueFormat, $el.attr('value')),
              day = date.getDate(),
              month = date.getMonth() + 1,
              year = date.getFullYear(),
              format = displayFormat;

            if (1 === day && 1 === month) {
              format = 'yy';
            }

            $el.attr('value', $.datepicker.formatDate(format, date));
          }
        });

        if ($('.c-alert--note').length > 0) {
          setTimeout(function () {
            $('.c-alert--note').hide('slow');
          }, 4000)
        }

      }
    }, // end common



    //no longer used ?
    single_property: {
      init: function () {
        console.log('Is this used?  Single property');
        $('.js-spinner').hide();
        $('.js-property-details').validate({
          rules: {
            _gotcha: {
              maxlength: 0
            }
          },
          errorClass: 'is-invalid',
          validClass: 'is-valid',
          errorPlacement: function (error, element) {
            error.insertAfter(element.next());
          },
          highlight: function (element, errorClass, validClass) {
            $(element).addClass(errorClass).removeClass(validClass);
            $(element).parent().addClass(errorClass);
          },
          unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass).addClass(validClass);
            $(element).parent().removeClass(errorClass);
          },
          submitHandler: function (form) {
            var form_data = $(form).serialize();
            $.ajax({
              url: window.data.ajaxurl,
              method: 'get',
              data: {
                'action': 'hta_create_project',
                '_ajax_nonce': window.data.nonce,
                'property_data': form_data
              },
              beforeSend: function () {
                $('.js-spinner').show();
                $('.js-property-details__message').removeClass('c-alert--error').text();
              },
              success: function (response) {
                $('.js-spinner').hide();

                if (response) {
                  $('.js-submit-btn').prop('disabled', 'disabled');
                  $('.js-success').removeClass('u-hidden');
                  setTimeout(function () {
                    window.location = response;
                  }, 500);
                } else {
                  $(window).scrollTop(0);
                  $('.js-property-details__message').text('An error occurred while creating the project. Please try again.').addClass('c-alert--error is-visible');
                }
              }
            });
          }
        });
      }
    }, // end single_property

    //no longer used
    /*
    post_type_archive_project: {
      init: function () {
      }
    }, // end post_type_archive_project
    */

    //Used to be used to focus the input on login - no longer used
    /*
    page_template_page_login: {
      init: function () {
        console.log('Is this used?  Login page');
        setTimeout(function () {
          $('#user_login').trigger('blur');
        }, 200);
      }
    }, // end page_template_page_login
    */

    //no longer used
    /*
    is_filter_page: {
      init: function () {
        console.log('Is this used?  Filter page');
        $('.js-spinner').hide();
        $('.js-filter-items').on('focus', '.js-form-field , .js-dropdown', function () {
          $('.js-filter-items__message').removeClass('is-visible');
        });

        $('.js-reset-filters').on('click', function (e) {
          e.preventDefault();
          $('.js-form-field, .js-dropdown').val('').removeClass('has-value');
          $('.js-dropdown__value').text('');
          $('.js-dropdown__item').prop('checked', false);

          var $sumbitBtn = $('.js-filter-items .js-submit-btn');
          $sumbitBtn.addClass('is-reset');
          setTimeout(function () {
            $sumbitBtn.trigger('click');
          }, 10);
        });

        $('.js-filter-items').validate({
          rules: {
            _gotcha: {
              maxlength: 0
            }
          },
          errorClass: 'is-invalid',
          validClass: 'is-valid',
          errorPlacement: function (error, element) {
            if (element.hasClass('js-dropdown__item')) {
              error.insertAfter(element.closest('.js-dropdown__items'));
            } else {
              error.insertAfter(element.next());
            }
          },
          highlight: function (element, errorClass, validClass) {
            $(element).addClass(errorClass).removeClass(validClass);
            $(element).parent().addClass(errorClass);
          },
          unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass).addClass(validClass);
            $(element).parent().removeClass(errorClass);
          },
          submitHandler: function (form) {
            var form_data = $(form).serialize();
            //console.log(  $('.js-form-field:focus').val() );
            // if there is at least one value
            setTimeout(function () {
              if (($('.js-filter-items .has-value').length !== 0) ||
                $('.js-filter-items .js-submit-btn').hasClass('is-reset') ||
                (($('.js-form-field:focus').length !== 0) && ($('.js-form-field:focus').val() !== ''))) {
                var reset = false;
                if ($('.js-filter-items .js-submit-btn').hasClass('is-reset')) {
                  reset = true;
                }
                var filter_callback = $('.js-filter-items').data('cb');
                $.ajax({
                  url: window.data.ajaxurl,
                  method: 'get',
                  data: {
                    'action': filter_callback,
                    '_ajax_nonce': window.data.nonce,
                    'filter_data': form_data,
                    'reset': reset
                  },
                  beforeSend: function () {
                    $('.js-spinner').show();
                    $('.js-panel-close').trigger('click');
                    $('.js-submit-btn').removeClass('is-reset');
                    $('.js-items-container').empty();
                    $('.js-items-container').append('<p class="u-text-center js-spinner"><i class="c-btn__icon c-btn__icon--spinner c-btn__icon--spinner-black"></i></p>');
                  },
                  success: function (response) {
                    $('.js-spinner').hide();
                    if (response !== '') {
                      $('.js-items-container').append(response);
                    } else {
                      $('.js-items-container').append('<p class="u-text-center">No results.</p>');
                    }
                  }
                });
              } else {
                $('.js-filter-items__message').addClass('is-visible');
              }
            }, 20);
          }
        });
      }
    }, // end is_filter_page
    */

    //no longer used
    /*
    dashboard_concepts: {
      init: function () {

        // Concept Type Filtering
        $('.c-prototype-concept-btn').on('click', function () {
          var selected = $(this).data('kind');

          $('.c-prototype-concept-btn').removeClass('c-prototype-concept-btn--selected');
          $(this).addClass('c-prototype-concept-btn--selected');

          if (selected === 'all') {
            $('.c-prototype-container').addClass('c-prototype-container--active');
          } else {
            $('.c-prototype-container').removeClass('c-prototype-container--active');

            // convert to easily iterable array
            var arr = $.map($('.c-prototype-container'), function (value, index) {
              return [value];
            });

            arr.forEach(function (element, index) {
              // transform elements into jquery objects
              element = $(element);
              if (element.data('kind') === selected) {
                element.addClass('c-prototype-container--active');
              }
            });

          }

        });

        $('.js-btn-concept-modal').on('click', function () {
          var concept = $(this).data('modal');
          $('.concept-modal').removeClass('concept-modal-active');
          $('.concept-modal[data-modal="' + concept + '"]').addClass('concept-modal-active');


          if ($('.owl-carousel').length) {

            $(".owl-carousel").owlCarousel({
              loop: true,
              nav: false,
              responsive: {
                0: {
                  items: 1,
                },
                500: {
                  items: 1,
                },
                768: {
                  items: 1,
                }
              }
            });

          }

        });

        $('.modal-close').on('click', function () {
          var concept = $(this).data('modal');
          $('.concept-modal').removeClass('concept-modal-active');
        });
      }
    }, // end dashboard_concepts
    */

    //no longer needed
    /*
    partner_confirm: {
      init: function () {
        function partner_confirm(message, callback) {

          var dfd = $.Deferred();
          var dialog = $("<div>", {
            id: "confirm"
          })
            .html(message)
            .appendTo($("body"))
            .data("selection", false)
            .dialog({
              closeText: "x",
              autoOpen: false,
              resizable: false,
              title: 'Confirm',
              zIndex: 99999999,
              modal: true,
              draggable: false,
              buttons: [{
                text: "Yes",
                click: function () {
                  $(this).dialog("close");
                  dfd.resolve(true);
                  if ($.isFunction(callback)) {
                    callback.apply();
                  }
                }
              }, {
                text: "No",
                click: function () {
                  $(this).dialog("close");
                  dfd.resolve(false);
                }
              }],
              close: function (event, ui) {
                $('#confirm').remove();
              },
              open: function () {
                $(this).closest(".ui-dialog")
                  .find(".ui-dialog-titlebar-close")
                  .addClass("note-modal-close")
                  .html("<svg style='left:0px;' class='o-icon c-note-icon'><use xlink:href='#icon-close'></use></svg>");
              }
            });
          dialog.dialog("open");
          return dfd.promise();
        }

        function partner_data(message, callback) {

          var dfd = $.Deferred();
          var dialog = $('<input type="text" class="c-form__field" value="" id="input_partner_name" style="max-width: unset;">', {
            id: "confirm"
          })
            .html(message)
            .appendTo($("body"))
            .data("selection", false)
            .dialog({
              closeText: "x",
              autoOpen: false,
              resizable: false,
              title: 'Enter the Partner\'s Name',
              zIndex: 99999999,
              modal: true,
              draggable: false,
              buttons: [
                {
                  text: "Enter",
                  click: function () {
                    $(this).dialog("close");
                    dfd.resolve(true);
                    if ($.isFunction(callback)) {
                      callback.apply();
                    }
                  }
                },
                {
                  text: "Cancel",
                  click: function () {
                    $(this).dialog("close");
                    dfd.resolve(false);
                  }
                },
              ],
              close: function (event, ui) {
                $('#confirm').remove();
              },
              open: function () {
                $(this).closest(".ui-dialog")
                  .find(".ui-dialog-titlebar-close")
                  .addClass("note-modal-close")
                  .html("<svg style='left:0px;' class='o-icon c-note-icon'><use xlink:href='#icon-close'></use></svg>");
                $(this).closest(".ui-dialog").find("#input_partner_name").css('width', 'inherit');
              }
            });
          dialog.dialog("open");
          return dfd.promise();
        }

        $(function () {


          $('#concept_details_form').submit(function (e) {
            e.preventDefault();
            var activeNav = $('.c-btn--active');

            // check if bespoke is the chosen concept
            if (activeNav.find('input#concept_id').data('prototype') === 'Bespoke') {
              // if it is, check to see if partner name is left blank
              if ($('input#partner_name').val() === '') {
                $.when(partner_confirm("Is there a partner?")).done(function (val) {
                  // chose yes
                  if (val) {

                    $.when(partner_data('Enter the Partner\'s Name.', function () {
                      var partnerName = $('#input_partner_name').val();
                      $('input#partner_name').val(partnerName);
                    })).done(function (val) {
                      return true;
                    });
                    return true;
                  }
                  else {
                    // return true;
                    $('#concept_details_form')[0].submit();
                  }
                });
              }
              else {
                // return true;
                $('#concept_details_form')[0].submit();
              }
            }
            else {
              // return true;
              $('#concept_details_form')[0].submit();
            }


          });
        });
      }
    }, // end partner_confirm
    */

    //used to handle adding/deleting concepts on the project details and new project no longer used
    /*
    dashboard_property: {
      init: function () {

        function ui_confirm(message, callback) {
          var dfd = $.Deferred();
          var dialog = $("<div>", {
            id: "confirm"
          })
            .html(message)
            .appendTo($("body"))
            .data("selection", false)
            .dialog({
              closeText: "x",
              autoOpen: false,
              resizable: false,
              title: 'Confirm',
              zIndex: 99999999,
              modal: true,
              draggable: false,
              buttons: [{
                text: "Yes",
                click: function () {
                  $(this).dialog("close");
                  dfd.resolve(true);
                  if ($.isFunction(callback)) {
                    callback.apply();
                  }
                }
              }, {
                text: "No",
                click: function () {
                  $(this).dialog("close");
                  dfd.resolve(false);
                }
              }],
              close: function (event, ui) {
                $('#confirm').remove();
              },
              open: function () {
                $(this).closest(".ui-dialog")
                  .find(".ui-dialog-titlebar-close")
                  .addClass("note-modal-close")
                  .html("<svg style='left:0px;' class='o-icon c-note-icon'><use xlink:href='#icon-close'></use></svg>");
              }
            });
          dialog.dialog("open");
          return dfd.promise();
        }

        var conceptNumInputEl = $('#proj_number_concepts');
        var numConcepts = conceptNumInputEl.val();
        var counterConcepts = numConcepts;
        var conceptNumEl = $('#concept_num');
        var conceptSelectsContainerEl = $('#concept_selects_container');
        var conceptSelectsEl = $('.concept_select');

        function templateFactory(num) {
          var template = '<div class="o-layout-form__item concept_select">\
                                  <div class="c-form__group--select">\
                                    <select class="c-form__field c-form__field--select js-form-field" id="concept-' + num + '" name="concept[' + num + ']">\
                                    </select>\
                                    <a href="#" class="delete_concept" data-id="concept-' + num + '">Delete concept</a>\
                                  </div>\
                                </div>';
          var $el = $(template);

          var $hiddenSelect = $('#hidden-select');
          var hiddenSelectOptions = $hiddenSelect.find('option').clone();
          //              var was69 = false;
          //              var el69 = '<option value="69">Third Party</option>';
          //              $.each(hiddenSelectOptions, function(i, v){
          //                if($(v).val() == 69) {
          //                  was69 = true;
          //                }
          //              });
          var $options = $($hiddenSelect).children().slice(1).clone();


          var $selects = $('.concept_select');
          var selected = $selects.find(":selected");
          //              if(was69)
          $el.find('select').append('<option selected value="">Concept</option>').append(hiddenSelectOptions);
          //              else
          //                $el.find('select').append('<option selected value="">Concept</option>' + el69).append(hiddenSelectOptions);

          return $el;
        }

        function renderSelects() {


          // console.log($selects, selected, hiddenSelectOptions);

        }

        function updateSelectInput() {
          conceptNumInputEl.val(counterConcepts);
          conceptNumEl.text(counterConcepts);
        }

        function addSelect() {
          var newEl = templateFactory(numConcepts - 1);
          conceptSelectsContainerEl.append(newEl);
          newEl.find('select').select2({ minimumResultsForSearch: -1 });
        }

        function removeSelect() {
          var targetEl = $('.concept_select:last');
          targetEl.remove();
        }

        function removeConcept(ID) {
          var targetEl = $('select#' + ID).closest('.concept_select');
          targetEl.remove();
        }

        function incConcepts() {
          if (numConcepts === 20) {
            return false;
          }
          numConcepts++;
          counterConcepts++;
          updateSelectInput();
          addSelect();
        }

        function decConcepts() {
          if (numConcepts === 1) {
            return false;
          }
          //              numConcepts--;
          counterConcepts--;
          updateSelectInput();
          removeSelect();
          renderSelects();
        }

        function deleteConcept(ID) {
          if (numConcepts === 1) {
            return false;
          }
          //              numConcepts--;
          counterConcepts--;
          updateSelectInput();
          //              removeSelect();
          removeConcept(ID);
          renderSelects();
        }

        $('').on('click', function (e) {
          e.preventDefault();
        });

        $('#concept_dec').on('click', function (e) {
          e.preventDefault();
          $.when(ui_confirm("Are you sure?")).done(function (val) {
            if (val) {
              decConcepts()
            } else {
              console.log('oops');
            }

          });
        });

        $('body').on('click', '.delete_concept', function (e) {
          e.preventDefault();
          var ID = $(this).data("id");
          $.when(ui_confirm("Are you sure?")).done(function (val) {
            if (val) {
              //                        decConcepts()
              deleteConcept(ID);
            } else {
              console.log('oops');
            }

          });
        });


        $('#concept_inc').on('click', function (e) {
          e.preventDefault();
          incConcepts();
        });

        // $('.js-submit-btn').on('');
      }
    }, // end dashboard_property
    */



    //no longer used - need to copy seating validation??
    dashboard_details: {
      init: function () {

        if ($('#agreement').length) {
          var $agreement = $('#agreement');
          var $partnerFee = $('#partner_fee-select');
          var $feeName = $('#fee_name-input');
/*
          function checkAgreement() {
            var val = $($agreement).find(':selected').val();
            if (val === 'partner' || val === 'management-agreement') {
              //                $feeName.show();
              $partnerFee.show();
            } else {
              //                $partnerFee.find('#partner_fee').removeAttr('value');
              $partnerFee.find('#partner_fee').val("").trigger('change');
              $partnerFee.hide();
              //                $feeName.find('#prop_fee_name').removeAttr('value');
              //                $feeName.hide();
            }
            // console.log(val, $partnerFee);

          }

          // Check on load
          checkAgreement();

          $($agreement).on('change', function () {
            checkAgreement();
          });*/
        }


        var disabledConcept = $('#disabled_concept').val();
        // console.log(disabledConcept);
        if (disabledConcept == 'true') {
          $('#concept_details_form .c-form__field')
            .not('#size, #measurement')
            .each(function (index, el) {
              $(this).attr('disabled', 'disabled');
            });

          $('#concept_details_form button')
            .not('.js-submit-btn')
            .each(function (index, el) {
              $(this).attr('disabled', 'disabled');
              $(this).css('pointer-events', 'none');
            });
        }

        // SEATING STYLE fileds group validation.
        $('.js-submit-btn').on('click', conceptDetailsUpdated);
        function conceptDetailsUpdated(event) {
          var $this = $(this),
            $form = $this.closest('form'),
            result = true;

          result = seatingStyleValidation($form);

          if (!result) {
            return result;
          }

          result = renovationTypeValidation($form);

          return result;
        }

        function seatingStyleValidation($form) {
          var $seatingNew = $form.find('.seating_new:visible'),
          result = true;

          if (!$seatingNew.length) {
            return result;
          }

          $seatingNew.each(function (index, group) {
            var $group = $(group),
              $styleSelectWrap = $group.find('.c-form__group--select'),
              $styleSelectField = $styleSelectWrap.find('select'),
              $styleSelectError = $styleSelectWrap.find('.is-invalid');

            if ('' === $styleSelectField.val()) {
              $styleSelectError.removeClass('u-hidden');
              result = false;
              return !1;

            } else {
              $styleSelectError.addClass('u-hidden');
            }
          });

          return result;
        }

        function renovationTypeValidation($form) {
          var $renovationTypeField = $('#renovation_type'),
            $renovationTypeError = $('#renovation_type-error');

          if (!$renovationTypeField.is(':visible')) {
            return true;
          }

          if ('' == $renovationTypeField.val()) {
            $renovationTypeError.removeClass('u-hidden');
            return false;

          } else {
            $renovationTypeError.addClass('u-hidden');
            return true;
          }
        }

        $('#concept-status').on('change', conceptStatusChanged);
        function conceptStatusChanged(event) {
          var $this = $(this),
            $form = $this.closest('form'),
            $renovationWrapper = $form.find('.o-layout-form__item--renovation');

          event.preventDefault();

          if ('Renovation' === $this.val()) {
            $renovationWrapper.removeClass('u-hidden');
          } else {
            $renovationWrapper.addClass('u-hidden');
          }

        }

      }
    } // end dashboard_details

  };
