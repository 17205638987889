//Dashboard Charts and Reports

//console.log('charts and reports loading');

export var dashboardCharts = {
  dashboard_charts: {
    init: function() {
      // console.log('dashboard_charts: init');

      // Glogal settings for Chart.js
      window.Chart.defaults.global.defaultFontColor = '#fff';
      // Chart.defaults.global.defaultFontFamily = "'DIN', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
      window.Chart.defaults.global.defaultFontFamily = "'DIN', sans-serif";
      window.Chart.defaults.global.defaultFontSize = 15;
      window.Chart.defaults.global.hover.mode = false;
      window.Chart.defaults.global.tooltips.enabled = false;
      window.Chart.defaults.global.legend.display = false;
      window.Chart.defaults.global.responsive = false;
      window.Chart.defaults.global.devicePixelRatio = 2.5;

      //Unregister data labels for all charts
      window.Chart.plugins.unregister(ChartDataLabels);

      //Background color for bar charts
      var barColorsPlugin = {
        afterDatasetsDraw: function(chartInstance) {
          var ctx = chartInstance.chart.ctx,
            width = chartInstance.chartArea.right;
          chartInstance.data.datasets.forEach(function(dataset, datasetIndex) {
            var datasetMeta = chartInstance.getDatasetMeta(datasetIndex);
            datasetMeta.data.forEach(function(segment, segmentIndex) {
              var height = segment._model.height,
                posX = segment.tooltipPosition().x,
                posY = segment.tooltipPosition().y - height / 2;
              // draw white background
              ctx.save();
              ctx.fillStyle = '#f1f1f1';
              ctx.fillRect(posX, posY, width - posX, height);
              ctx.restore();
            });
          });
        }
      };

      // Charts `doughnut` (in values) init.
      $('.js-chart-doughnut-in-values').each(function(index, chart) {
        var $chart = $(chart),
          $label = $chart.find('.c-chart__label'),
          $badge = $chart.find('.c-chart__badge'),
          canvas = $chart.find('.c-chart__canvas'),
          config = $chart.data('chart'),
          filter = $chart.data('filter'),
          excluded = config.exclude,
          legendLabels = [],
          legendValues = [],
          legendColors = $chart.data('legend-colors'),
          chartColors = $chart.data('chart-colors'),
          $select = $('#' + filter),
          selected = $select.find('option:selected').val(),
          labels = [],
          total = 0,
          //chartTotal = config.chartTotal,
          instance,
          key,
          item_value;

        //Prepare data and labels
        //Iterate through the items of the selected filter category
        //For projects, selected should be user, for concepts selected should be region
        //Key will always be status
        for (key in config.data[selected]) {
          //Get the current item value
          item_value = config.data[selected][key];
          //Check if we're excluding anything (array exists), and key is in excluded array.  Increment the total, if key is not excluded
          if (!excluded.includes(key)) total += item_value;
          //Add to the labels
          labels.push({ label: key, value: config.data[selected][key] });
          //After this we prob don't need the above, let's just hope the indexes stay in sync!
          legendLabels.push(key + (excluded.includes(key) ? '*' : ''));
          legendValues.push(config.data[selected][key]);
        }

        //normalize data
        var data = [total, config.chartTotal];

        var ratio = Math.max.apply(Math, data) / 100,
          l = data.length,
          i;

        for (i = 0; i < l; i++) {
          //normalize left data
          data[i] = Math.round(data[i] / ratio);
        }

        // Create new Chart instance.
        instance = new window.Chart(canvas, {
          type: 'doughnut',
          // The data for our dataset
          data: {
            labels: labels, //need to show to successfully stack?
            legendLabels: legendLabels,
            legendValues: legendValues,
            legendColors: legendColors,
            totalConcepts: total,
            datasets: [
              {
                data: [data[0], 100 - data[0]],
                backgroundColor: chartColors,
                borderWidth: 0
              }
            ]
          },
          // Configuration options go here
          options: {
            cutoutPercentage: 65, //Width of donut
            legendCallback: function(chart) {
              var text = [],
                i;

              text.push(
                '<div class="c-chart__legend-inner"><ul class="c-chart__legend-items">'
              );

              for (i = 0; i < chart.data.legendLabels.length; i++) {
                //Can't use the chart total because it excludes values, instead sum the legend values
                var totalLegend = chart.data.legendValues.reduce(
                  (a, b) => a + b,
                  0
                );

                text.push(
                  '<li class="c-chart__legend-item" style="visibility: visible" data-label="' +
                    chart.data.legendLabels[i] +
                    '">'
                );
                text.push(
                  '<span class="c-chart__legend-item-value" data-val="' +
                    chart.data.legendValues[i] +
                    '">0</span>'
                );
                text.push(
                  '<span class="c-chart__legend-item-color-wrap"> <span class="c-chart__legend-item-color" data-val="' +
                    (chart.data.legendValues[i] / totalLegend) * 100 +
                    '" style="background-color:' +
                    chart.data.legendColors[i] +
                    '; width:0%!important;"></span></span>'
                );
                text.push(
                  '<span class="c-chart__legend-item-label">' +
                    chart.data.legendLabels[i] +
                    '</span>'
                );
                text.push('</li>');

                /*
                    text.push('<li class="c-chart__legend-item" style="visibility: visible" data-label="' + labels[i] + '">');
                    text.push('<span class="c-chart__legend-item-value">' + chart.data.datasets[0].data[i] + '</span>');
                    text.push('<span class="c-chart__legend-item-color" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '"></span>');
                    text.push('<span class="c-chart__legend-item-label">' + chart.data.labels[i] + '</span>');
                    */
              }

              text.push('</ul></div>');

              return text.join('');
            }
          }
        });

        // Total counters.
        $('.dashboard_stat').each(function() {
          var $this = $(this),
            value = $this.data('value');

          animateChartCounter($this, value);
        });

        // Animate total badge
        if ($badge) animateChartCounter($badge, total);

        // Animate counters.
        if ($chart.hasClass('c-chart--percent'))
          animateChartCounter($label, (total / config.chartTotal) * 100);
        else animateChartCounter($label, total);

        // Generate legend.
        $chart.find('.c-chart__legend').html(instance.generateLegend());
        // Animate legend
        $chart.find('.c-chart__legend-item-value').each(function() {
          animateChartCounter($(this), $(this).data('val'));
        });
        $chart.find('.c-chart__legend-item-color').each(function() {
          $(this).css('width', '');
          animateChartBar($(this), $(this).data('val'));
        });

        // Filter handler.
        $select.on('change', function(event) {
          console.log('doing a thing');
          var $target = $(event.currentTarget),
            selected = $target.find('option:selected').val(),
            //data = [],
            total = 0,
            legendLabels = [],
            legendValues = [],
            key,
            item_value;

          event.preventDefault();

          for (key in config.data[selected]) {
            //Get the current item value
            item_value = config.data[selected][key];
            //Check if we're excluding anything (array exists), and key is in excluded array.  Increment the total, if key is not excluded
            if (!excluded.includes(key)) total += item_value;
            //Add to the labels
            labels.push({ label: key, value: config.data[selected][key] });
            //After this we prob don't need the above, let's just hope the indexes stay in sync!
            legendLabels.push(key + (excluded.includes(key) ? '*' : ''));
            legendValues.push(config.data[selected][key]);
          }

          //normalize data
          var data = [total, config.chartTotal];

          var ratio = Math.max.apply(Math, data) / 100,
            l = data.length,
            i;

          for (i = 0; i < l; i++) {
            //normalize left data
            data[i] = Math.round(data[i] / ratio);
          }
          /*
              for (key in config.data[selected]) {
                var $legend_item = $chart.find('.c-chart__legend-item[data-label="' + key + '"]');

                item_value = config.data[selected][key].value;

                total += item_value;
                data.push(item_value);
                animateChartCounter($legend_item.find('.c-chart__legend-item-value'), item_value);
              } */
          /*
              // Update data.
              data: {
                labels: labels, //need to show to successfully stack?
                legendLabels: legendLabels,
                legendValues: legendValues,
                legendColors: legendColors,
                totalConcepts: total,
                datasets: [{
                  data: [data[0],100-data[0]],
                  backgroundColor: chartColors,
                  borderWidth: 0,
                }] */
          instance.data.legendValues = legendValues;
          instance.data.totalConcepts = total;
          (instance.data.datasets[0].data = [data[0], 100 - data[0]]),
            instance.update();

          // Animate total badge
          if ($badge) animateChartCounter($badge, total);

          // Animate counters.
          if ($chart.hasClass('c-chart--percent'))
            animateChartCounter($label, (total / config.chartTotal) * 100);
          else animateChartCounter($label, total);

          // Generate legend.
          $chart.find('.c-chart__legend').html(instance.generateLegend());
          // Generate legend.
          $chart.find('.c-chart__legend').html(instance.generateLegend());
          // Animate legend
          $chart.find('.c-chart__legend-item-value').each(function() {
            animateChartCounter($(this), $(this).data('val'));
          });
          $chart.find('.c-chart__legend-item-color').each(function() {
            $(this).css('width', '');
            animateChartBar($(this), $(this).data('val'));
          });
        });
      });

      // Charts `doughnut` (in percent) init.
      $('.js-chart-doughnut-in-percent').each(function(index, chart) {
        var $chart = $(chart),
          $label = $chart.find('.c-chart__label'),
          $badge = $chart.find('.c-chart__badge'),
          canvas = $chart.find('.c-chart__canvas'),
          config = $chart.data('chart'),
          filter = $chart.data('filter'),
          $select = $('#' + filter),
          $selected = $select.find('option:selected'),
          counter = $selected.val(),
          total = $selected.data('total'),
          instance;

        // Create new Chart instance.
        instance = new window.Chart(canvas, {
          type: 'doughnut',

          // The data for our dataset
          data: {
            datasets: [
              {
                data: config.data,
                backgroundColor: config.colors,
                borderWidth: 0
              }
            ]
          },

          // Configuration options go here
          options: {
            cutoutPercentage: 84
          }
        });

        $chart.css('color', instance.data.datasets[0].backgroundColor[1]);

        // Animate counters.
        animateChartCounter($label, counter);
        animateChartCounter($badge, total);

        // Filter handler.
        $select.on('change', function(event) {
          var $target = $(event.currentTarget),
            $selected = $target.find('option:selected'),
            counter = $selected.val(),
            total = $selected.data('total');

          event.preventDefault();

          // Update data.
          instance.data.datasets[0].data = [100 - counter, counter];
          instance.update();

          // Animate counters.
          animateChartCounter($label, counter);
          animateChartCounter($badge, total);
        });
      });

      // Verical bars.
      $('.js-chart-vertical-bars').each(function(index, chart) {
        var $chart = $(chart),
          canvas = $chart.find('.c-chart__canvas'),
          config = $chart.data('chart'),
          filter1 = $chart.data('filter-first'),
          filter2 = $chart.data('filter-second'),
          //filter = $chart.data('filter'),
          //filter2 = $chart.data('filter2'),
          $select1 = $('#' + filter1),
          selected1 = $select1.find('option:selected').val(),
          $select2 = $('#' + filter2),
          selected2 = $select2.find('option:selected').val(),
          data = [],
          dataBottom = [],
          dataTop = [],
          labels = [],
          legendLabels = [],
          legendValues = [],
          legendColors = $chart.data('legend-colors'),
          chartColors = $chart.data('chart-colors'),
          legend = [],
          keys = [],
          total = 0,
          maxAxesY = 100,
          legendRequired = true,
          instance,
          key;

        console.log('vertical bars');
        console.log(selected1);
        console.log(selected2);
        // Prepare data and labels.
        for (key in config.data[selected1][selected2]) {
          total += config.data[selected1][selected2][key];
          data.push(config.data[selected1][selected2][key]);
          labels.push(key);

          if (config.data[selected1][selected2][key].hasOwnProperty('legend')) {
            legend.push(config.data[selected1][selected2][key].legend);
            keys.push(key);
          }
        }

        if (!legend.length) {
          legendRequired = false;
        }

        if (
          typeof config.extra === 'object' &&
          true === config.extra.in_percent
        ) {
          maxAxesY = 100;
        } else {
          maxAxesY = total;
        }

        //we need to normalize the data
        //that means we need the left and right datasets to add up to the max width of the chart
        //and we also need the original left data set for the labels

        var ratio = Math.max.apply(Math, data) / 224,
          l = data.length,
          i;

        for (i = 0; i < l; i++) {
          //normalize left data
          var newBottom = Math.round(data[i] / ratio);
          dataBottom.push(newBottom);
          dataTop.push(Math.round(224 - newBottom));
        }

        //var maxAxesX = longest;
        //maxAxesX += (maxAxesX * .15); // for labels
        //console.log("Max X "+maxAxesX);

        //we need 2 datasets - 1 with the actual values, 1 with values up to maxAxes
        //for(let item of data){
        //console.log(item);
        //  dataRight.push(Math.round(150 - item));
        //}

        maxAxesY += 70; // for labels

        // Create new Chart instance.
        instance = new window.Chart(canvas, {
          type: 'bar',
          plugins: [window.ChartDataLabels],
          // The data for our dataset
          data: {
            labels: labels,
            originalData: data,
            datasets: [
              {
                data: dataBottom,
                backgroundColor: config.colors,
                borderWidth: 0,
                datalabels: {
                  anchor: 'start',
                  align: 'bottom',
                  offset: 0,
                  formatter: function(value, context) {
                    var str = labels[context.dataIndex];
                    //str.split(' ').join('\n');
                    //console.log(str);
                    return String(str).toUpperCase();
                  }
                }
              },
              {
                data: dataTop,
                backgroundColor: '#f1f1f1',
                datalabels: {
                  font: {
                    size: 14,
                    family: "'DIN',sans-serif",
                    weight: '500'
                  },

                  anchor: 'start',
                  align: 'top',
                  formatter: function(value, context) {
                    var this_data = context.chart.data.originalData;
                    //console.log(this_data);
                    var this_str = this_data[context.dataIndex];
                    //console.log(this_str);
                    var str = this_str;
                    return String(str).toUpperCase();
                  },
                  offset: -2,
                  padding: {
                    right: 0,
                    left: 0,
                    bottom: 0
                  }
                }
              }
            ]
          },
          // Configuration options go here
          options: {
            plugins: {
              // Change options for ALL labels of THIS CHART
              datalabels: {
                color: '#4d4d4d',
                font: {
                  family: "'DIN',sans-serif",
                  size: 9,
                  weight: '700'
                }
              }
            },
            responsive: false,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  display: false,
                  stacked: true,
                  barPercentage: 0.85,
                  categoryPercentage: 1,
                  // maxBarThickness: 50
                }
              ],
              yAxes: [
                {
                  display: false,
                  stacked: true,
                  beginAtZero: true,
                  ticks: {
                    min: -20,
                    max: 250
                  }
                }
              ]
            } /*
                legendCallback: function (chart) {
                  var text = [],
                    i;

                  if (!legendRequired) {
                    return !1;
                  }

                  text.push('<div class="c-chart__legend-inner"><ul class="c-chart__legend-items">');

                  for (i = 0; i < legend.length; i++) {
                    text.push('<li class="c-chart__legend-item" style="visibility: visible" data-label="' + keys[i] + '">');
                    text.push('<span class="c-chart__legend-item-value">' + legend[i] + '</span>');
                    text.push('</li>');
                  }

                  text.push('</ul></div>');

                  return text.join('');
                },*/,
            events: false,
            animation: {
              /* onComplete: function (animation) {
                    var chartInstance = animation.chart,
                      ctx = chartInstance.ctx;

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    ctx.fillStyle = '#4e4e4e';
                    ctx.font = window.Chart.helpers.fontString(9, 700, window.Chart.defaults.global.defaultFontFamily);

                    chartInstance.data.datasets.forEach(function (dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);

                      meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index],
                          current_label = labels[index].toUpperCase();
                        // y = bar._model.y + 25;

                        // if ( ( data * 3 ) < maxAxesY ) {
                        // ctx.textBaseline = 'bottom';
                        // ctx.fillStyle = '#000';
                        // y = bar._model.y - 10;

                        // } else {
                        //   ctx.textBaseline = 'top';
                        //   ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                        // }

                        // if ( ! animation.animationObject.numSteps ) {
                        //   ctx.font = Chart.helpers.fontString( 15, 900, Chart.defaults.global.defaultFontFamily );

                        //   if ( ( typeof( config.extra ) === 'object' ) && true === config.extra.in_percent ) {
                        //     data += '%';
                        //   }

                        //   ctx.fillText( data, bar._model.x, bar._model.y + 25 );
                        // }

                        // ctx.font = Chart.helpers.fontString( 7, 500, Chart.defaults.global.defaultFontFamily );
                        cutomWrapText(ctx, current_label, bar._model.x, bar._model.y - 10, 48, 9);
                      });
                    });
                  },*/
              onProgress: function(animation) {
                var chartInstance = animation.chart,
                  ctx = chartInstance.ctx,
                  progress = 1;

                if (animation.animationObject.numSteps) {
                  progress =
                    animation.animationObject.currentStep /
                    animation.animationObject.numSteps;
                }

                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                ctx.fillStyle = '#000';
                ctx.font = window.Chart.helpers.fontString(
                  15,
                  900,
                  window.Chart.defaults.global.defaultFontFamily
                );

                chartInstance.data.datasets.forEach(function(dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);

                  meta.data.forEach(function(bar, index) {
                    var data = Math.round(dataset.data[index] * progress);
                    //var _data = dataset.data[index];
                    // y     = bar._model.y + 5;

                    // if ( ( _data * 3 ) < maxAxesY ) {
                    // ctx.textBaseline = 'bottom';
                    // ctx.fillStyle = '#000';
                    // y  = bar._model.y - 20;

                    // } else {
                    //   ctx.textBaseline = 'top';
                    //   ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                    // }

                    if (
                      typeof config.extra === 'object' &&
                      true === config.extra.in_percent
                    ) {
                      data += '%';
                    }

                    //ctx.fillText(data, bar._model.x, bar._model.y - 20);
                  });
                });
              }
            }
          }
        });

        // Generate legend.
        if (legendRequired) {
          //$chart.find('.c-chart__legend').html(instance.generateLegend());
        }

        if ($chart.find('.c-chart__legend-total-value').length) {
          animateChartCounter(
            $chart.find('.c-chart__legend-total-value'),
            total
          );
        }

        $('.hta_brands_selects').on('change', function(event) {
          var $target = $(event.currentTarget),
            legend = [],
            data = [],
            dataBottom = [],
            dataTop = [],
            labels = [],
            total = 0,
            selected1 = $select1.find('option:selected').val(),
            selected2 = $select2.find('option:selected').val(),
            key;

          event.preventDefault();

          console.log('vertical bars filter');
          console.log(selected1);
          console.log(selected2);
          // Prepare data and labels.
          for (key in config.data[selected1][selected2]) {
            total += config.data[selected1][selected2][key];
            data.push(config.data[selected1][selected2][key]);
            labels.push(key);

            if (
              config.data[selected1][selected2][key].hasOwnProperty('legend')
            ) {
              legend.push(config.data[selected1][selected2][key].legend);
              keys.push(key);
            }
          }

          if (!legend.length) {
            legendRequired = false;
          }

          if (
            typeof config.extra === 'object' &&
            true === config.extra.in_percent
          ) {
            maxAxesY = 100;
          } else {
            maxAxesY = total;
          }

          //we need to normalize the data
          //that means we need the left and right datasets to add up to the max width of the chart
          //and we also need the original left data set for the labels

          var ratio = Math.max.apply(Math, data) / 224,
            l = data.length,
            i;

          for (i = 0; i < l; i++) {
            //normalize left data
            var newBottom = Math.round(data[i] / ratio);
            dataBottom.push(newBottom);
            dataTop.push(Math.round(224 - newBottom));
          }
          /*

              for (key in config.data[selected]) {
                var $legend_item = $chart.find('.c-chart__legend-item[data-label="' + key + '"]');

                data.push(config.data[selected][key].value);

                animateChartCounter($legend_item.find('.c-chart__legend-item-value'), config.data[selected][key].legend);
              } */

          // Update data.
          instance.data.originalData = data;
          instance.data.datasets[0].data = dataBottom;
          instance.data.datasets[1].data = dataTop;
          //instance.data.labels = labels;
          instance.update();
        });
      });

      // Charts `bar horizontal` init.
      $('.js-chart-horizontal-bars').each(function(index, chart) {
        var $chart = $(chart),
          canvas = $chart.find('.c-chart__canvas'),
          $badge = $chart.find('.c-chart__badge'),
          config = $chart.data('chart'),
          filter = $chart.data('filter'),
          $select = $('#' + filter),
          selected = $select.find('option:selected').val(),
          data = [],
          dataLeft = [],
          dataRight = [],
          labels = [],
          labelsRight = [],
          legend = [],
          total = 0,
          instance,
          key;

        var longest = 0;
        // Prepare data and labels.
        for (key in config.data[selected]) {
          //if(config.data[selected][key].value > longest)
          //longest = config.data[selected][key].value
          data.push(config.data[selected][key].value);
          //dataRight.push(config.data[selected][key].value);
          labels.push(config.data[selected][key].label);
          labelsRight.push(config.data[selected][key].value);
          legend.push(config.data[selected][key].legend);
          //total += config.data[selected][key].value;
        }

        console.log('Data: ');
        console.log(data);
        total = data.reduce(function(a, b){
          return a + b;
        }, 0);
        console.log('Horizontal total: ' + total);
        //we need to normalize the data
        //that means we need the left and right datasets to add up to the max width of the chart
        //and we also need the original left data set for the labels

        var ratio = total / 130, //ratio = Math.max.apply(Math, data) / 105,
          l = data.length,
          i;

        //normalize total
        total = Math.round(total / ratio);
        console.log('Normalized total: ' + total);

        for (i = 0; i < l; i++) {
          //normalize left data
          var newLeft = Math.round(data[i] / ratio);
          dataLeft.push(newLeft);
          dataRight.push(Math.round(total - newLeft));
        }

        //var maxAxesX = longest;
        //maxAxesX += (maxAxesX * .15); // for labels
        //console.log("Max X "+maxAxesX);

        //we need 2 datasets - 1 with the actual values, 1 with values up to maxAxes
        //for(let item of data){
        //console.log(item);
        //  dataRight.push(Math.round(150 - item));
        //}

        // Create new Chart instance.
        instance = new window.Chart(canvas, {
          type: 'horizontalBar',
          plugins: [window.ChartDataLabels],
          // The data for our dataset
          data: {
            labels: labels,
            originalData: data,
            datasets: [

              {
                data: dataRight,
                backgroundColor: '#f1f1f1',
                datalabels: {
                  anchor: 'start',
                  align: 'right',
                  formatter: function(value, context) {
                    console.log('formatting a label');
                    var this_data = context.chart.data.originalData;
                    console.log(this_data);
                    var this_str = this_data[context.dataIndex];
                    console.log(this_str);
                    var str = this_str;
                    return String(str).toUpperCase();
                  },
                  padding: {
                    right: 0,
                    left: 7
                  },
                  font: {
                    family: "'DIN',sans-serif",
                    size: 12,
                    lineHeight: 1,
                    weight: '500'
                  }
                }
              },
              {
                data: dataLeft,
                backgroundColor: config.colors,
                borderWidth: 0,
                datalabels: {
                  anchor: 'end',
                  align: 'right',
                  formatter: function(value, context) {
                    var str = labels[context.dataIndex];
                    str = str.split(' ');
                    //console.log("did we split? " + str);
                    //return String(str);
                    return str;
                    //return ['test','string'];
                  },
                  padding: {
                    right: 10,
                    left: 0
                  }
                }
              }
            ]
          },

          // Configuration options go here
          options: {
            plugins: {
              // Change options for ALL labels of THIS CHART
              datalabels: {
                color: '#4d4d4d',
                font: {
                  family: "'DIN',sans-serif",
                  size: 9,
                  lineHeight: 1,
                  weight: '700'
                }
              }
            },
            responsive: false,
            maintainAspectRatio: true,
            padding: {
              // Any unspecified dimensions are assumed to be 0
              //left: 100
              //right: -100
            },
            scales: {
              xAxes: [
                {
                  //afterFit: function(scaleInstance) {
                  //  scaleInstance.width = ; // sets the width to 100px
                  //},
                  maxBarThickness: 200,
                  stacked: true,
                  display: false,
                  beginAtZero: true,
                  ticks: {
                    min: 0,
                    max: 170 // maxAxesX
                  }
                }
              ],
              yAxes: [
                {
                  stacked: true,
                  display: false,
                  barPercentage: 0.85,
                  categoryPercentage: 1
                }
              ]
            }
            /* legendCallback: function (chart) {
                  var text = [],
                    i;

                  text.push('<div class="c-chart__legend-inner"><ul class="c-chart__legend-items">');

                  for (i = 0; i < legend.length; i++) {
                    text.push('<li class="c-chart__legend-item" data-label="' + labels[i] + '">');
                    text.push('<span class="c-chart__legend-item-label">' + labels[i] + '</span>');
                    text.push('<span class="c-chart__legend-item-value">0</span>');
                    text.push('</li>');
                  }

                  text.push('</ul></div>');

                  return text.join('');
                },*/
            /*
                events: false,
                animation: {
                  onComplete: function (animation) {
                    var chartInstance = animation.chart,
                      ctx = chartInstance.ctx;
                    // fontSize      = 7;

                    ctx.font = window.Chart.helpers.fontString(9, 700, window.Chart.defaults.global.defaultFontFamily);
                    ctx.fillStyle = '#4e4e4e';
                    ctx.textBaseline = 'top';

                    chartInstance.data.datasets.forEach(function (dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);

                      meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        // x    = 5;

                        // if ( data < 15 ) {
                        //   return;
                        // }

                        // if ( ! animation.animationObject.numSteps ) {

                        //   if ( ( typeof( config.extra ) === 'object' ) && true === config.extra.in_percent ) {
                        //     data += '%';
                        //   }

                        //   ctx.textAlign = 'end';
                        //   ctx.fillText( data, bar._model.x - 15, bar._model.y );
                        // }

                        // if ( data < 40 ) {
                        //   ctx.fillStyle = '#000';
                        //   ctx.textBaseline = 'top';
                        //   x = bar._model.x + 5;
                        // } else {
                        //   ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                        //   ctx.textBaseline = 'middle';
                        // }

                        var l = labels[index];

                        ctx.textAlign = 'start';
                        ctx.fillText(l.toUpperCase(), bar._model.x + 5, bar._model.y);
                      });
                    });
                  },
                  onProgress: function (animation) {
                    var chartInstance = animation.chart,
                      ctx = chartInstance.ctx,
                      progress = 1;

                    if (animation.animationObject.numSteps) {
                      progress = animation.animationObject.currentStep / animation.animationObject.numSteps;
                    }

                    ctx.font = window.Chart.helpers.fontString(9, 700, window.Chart.defaults.global.defaultFontFamily);
                    ctx.fillStyle = '#000';
                    ctx.textAlign = 'start';
                    ctx.textBaseline = 'bottom';

                    chartInstance.data.datasets.forEach(function (dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);

                      meta.data.forEach(function (bar, index) {
                        var data = Math.round(dataset.data[index] * progress),
                          _data = dataset.data[index];
                        // x     = bar._model.x - 15;

                        // if ( _data < 40 ) {
                        //   ctx.fillStyle = '#000';
                        //   ctx.textAlign = 'start';
                        //   ctx.textBaseline = 'bottom';
                        //   x = bar._model.x + 5;
                        // } else {
                        //   ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                        //   ctx.textAlign = 'end';
                        //   ctx.textBaseline = 'middle';
                        // }

                        if ((typeof (config.extra) === 'object') && true === config.extra.in_percent) {
                          data += '%';
                        }

                        ctx.fillText(data, bar._model.x + 5, bar._model.y);
                      });
                    });
                  }
                }*/
          }
        });

        // Generate legend.
        //$chart.find('.c-chart__legend').html(instance.generateLegend());

        /*
            $chart.find('.c-chart__legend-item').each(function (i, bar) {
              var $bar = $(bar);

              $bar.css({
                color: config.colors[i],
                visibility: 'visible'
              });

              animateChartCounter($bar.find('.c-chart__legend-item-value'), legend[i]);
            });
            */

        // Animate total badge
        if ($badge) animateChartCounter($badge, total);

        $select.on('change', function(event) {
          var $target = $(event.currentTarget),
            selected = $target.find('option:selected').val(),
            data = [],
            legend = [],
            dataLeft = [],
            dataRight = [],
            labels = [],
            total = 0,
            key;

          event.preventDefault();

          /*

              for (key in config.data[selected]) {
                var $legend_item = $chart.find('.c-chart__legend-item[data-label="' + key + '"]');

                data.push(config.data[selected][key].value);

                animateChartCounter($legend_item.find('.c-chart__legend-item-value'), config.data[selected][key].legend);
              } */

          var longest = 0;
          // Prepare data and labels.
          for (key in config.data[selected]) {
            //if(config.data[selected][key].value > longest)
            //longest = config.data[selected][key].value
            data.push(config.data[selected][key].value);
            //dataRight.push(config.data[selected][key].value);
            //labels.push(config.data[selected][key].label);
            //labelsRight.push(config.data[selected][key].value);
            //legend.push(config.data[selected][key].legend);
            //total += config.data[selected][key].value;
          }

          total = data.reduce(function(a, b){
            return a + b;
          }, 0);
          console.log('Horizontal total: ' + total);
          //we need to normalize the data
          //that means we need the left and right datasets to add up to the max width of the chart
          //and we also need the original left data set for the labels

          var ratio = total / 130, //ratio = Math.max.apply(Math, data) / 105,
            l = data.length,
            i;

          //normalize total
          total = Math.round(total / ratio);
          console.log('Normalized total: ' + total);

          for (i = 0; i < l; i++) {
            //normalize left data
            var newLeft = Math.round(data[i] / ratio);
            dataLeft.push(newLeft);
            dataRight.push(Math.round(total - newLeft));
          }

          // Update data.
          instance.data.originalData = data;
          instance.data.datasets[0].data = dataRight;
          instance.data.datasets[1].data = dataLeft;
          //instance.data.labels = labels;
          instance.update();

          if ($badge) animateChartCounter($badge, total);

          //instance.data.legendValues = legendValues;
          //instance.data.totalConcepts = total;
          //instance.data.datasets[0].data = [data[0],100-data[0]],
          //instance.update();
          /*
              // Animate total badge
              if($badge)
                animateChartCounter($badge, total);

              // Animate counters.
              if($chart.hasClass('c-chart--percent'))
                animateChartCounter($label, (total / config.chartTotal)*100);
              else
                animateChartCounter($label, total);

              // Generate legend.
              $chart.find('.c-chart__legend').html(instance.generateLegend());
              // Generate legend.
              $chart.find('.c-chart__legend').html(instance.generateLegend());
              // Animate legend
              $chart.find('.c-chart__legend-item-value').each(function(){
                animateChartCounter($(this),$(this).data('val'));
              });
              $chart.find('.c-chart__legend-item-color').each(function(){
                $(this).css('width','');
                animateChartBar($(this),$(this).data('val'));
              }); */
        });
      });

      // Charts `mirror bar` init.
      $('.js-chart-mirror-bars').each(function(index, chart) {
        var $chart = $(chart),
          canvas = $chart.find('.c-chart__canvas'),
          config = $chart.data('chart'),
          filter = $chart.data('filter'),
          $select = $('#' + filter),
          selected = $select.find('option:selected').val(),
          labels = [],
          dataset_left = [],
          dataset_right = [],
          instance,
          key;

        // Prepare data and labels.
        for (key in config.data[selected]) {
          labels.push(config.data[selected][key].label);
          dataset_right.push(config.data[selected][key].value['Franchised']);
          dataset_left.push(-1 * config.data[selected][key].value['Managed']);
        }

        // Create new Chart instance.
        instance = new window.Chart(canvas, {
          type: 'horizontalBar',

          // The data for our dataset
          data: {
            labels: labels,
            datasets: [
              {
                backgroundColor: config.colors[0],
                borderWidth: 0,
                data: dataset_left
              },
              {
                backgroundColor: config.colors[1],
                borderWidth: 0,
                data: dataset_right
              }
            ]
          },

          // Configuration options go here
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  display: false,
                  stacked: true,
                  ticks: {
                    min: -120,
                    max: 120
                  }
                }
              ],
              yAxes: [
                {
                  display: false,
                  stacked: true,
                  barPercentage: 0.5,
                  categoryPercentage: 1.0
                }
              ]
            },
            events: false,
            animation: {
              onComplete: function(animation) {
                var chartInstance = animation.chart,
                  ctx = chartInstance.ctx;

                ctx.textAlign = 'center';

                chartInstance.data.datasets.forEach(function(dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);

                  meta.data.forEach(function(bar, index) {
                    var data = dataset.data[index],
                      halfWidth = bar._chart.width / 2;
                    data = Math.abs(data);

                    if (!i) {
                      // Draw label.
                      ctx.textBaseline = 'top';
                      ctx.fillStyle = '#4e4e4e';
                      ctx.font = window.Chart.helpers.fontString(
                        9,
                        700,
                        window.Chart.defaults.global.defaultFontFamily
                      );
                      ctx.fillText(
                        labels[index].toUpperCase(),
                        halfWidth,
                        bar._model.y + 12
                      );
                    }

                    // if ( animation.animationObject.numSteps ) {
                    //   return ! 1;
                    // }

                    // if ( data < 30 ) {
                    //   return ! 1;
                    // }

                    // if ( ( typeof( config.extra ) === 'object' ) && true === config.extra.in_percent ) {
                    //   data += '%';
                    // }

                    // ctx.textBaseline = 'middle';
                    // ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                    // ctx.font = Chart.helpers.fontString( 13, 700, Chart.defaults.global.defaultFontFamily );

                    // if ( i ) {

                    //   // right bar
                    //   ctx.fillText( data.toUpperCase(), halfWidth + 20, bar._model.y );

                    // } else {

                    //   // left bar
                    //   ctx.fillText( data.toUpperCase(), halfWidth - 20, bar._model.y );
                    // }
                  });
                });
              },
              onProgress: function(animation) {
                var chartInstance = animation.chart,
                  ctx = chartInstance.ctx,
                  progress = 1;

                if (animation.animationObject.numSteps) {
                  progress =
                    animation.animationObject.currentStep /
                    animation.animationObject.numSteps;
                }

                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';
                ctx.font = window.Chart.helpers.fontString(
                  13,
                  700,
                  window.Chart.defaults.global.defaultFontFamily
                );

                chartInstance.data.datasets.forEach(function(dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);

                  meta.data.forEach(function(bar, index) {
                    var data = Math.abs(
                      Math.round(dataset.data[index] * progress)
                    );
                    // halfWidth = bar._chart.width / 2;

                    // if ( data < 30 ) {
                    //   return;
                    // }

                    if (
                      typeof config.extra === 'object' &&
                      true === config.extra.in_percent
                    ) {
                      data += '%';
                    }

                    if (i) {
                      ctx.fillText(
                        data.toUpperCase(),
                        bar._model.x + 20,
                        bar._model.y
                      );
                    } else {
                      ctx.fillText(
                        data.toUpperCase(),
                        bar._model.x - 20,
                        bar._model.y
                      );
                    }
                  });
                });
              }
            }
          }
        });

        $select.on('change', function(event) {
          var $target = $(event.currentTarget),
            selected = $target.find('option:selected').val(),
            dataset_left = [],
            dataset_right = [],
            key;

          event.preventDefault();

          for (key in config.data[selected]) {
            dataset_right.push(config.data[selected][key].value['Franchised']);
            dataset_left.push(-1 * config.data[selected][key].value['Managed']);
          }

          // Update data.
          instance.data.datasets[0].data = dataset_left;
          instance.data.datasets[1].data = dataset_right;
          instance.update();
        });
      });

      // Charts Management init.
      $('.js-chart-management').each(function(index, chart) {
        var $chart = $(chart),
          canvas = $chart.find('.c-chart__canvas'),
          config = $chart.data('chart'),
          filter = $chart.data('filter'),
          $select = $('#' + filter),
          selected = $select.find('option:selected').val(),
          data = [],
          dataLeft = [],
          dataRight = [],
          labels = [],
          labelsRight = [],
          legend = [],
          total = 0,
          instance,
          key;

        var longest = 0;
        // Prepare data and labels.
        for (key in config.data[selected]) {
          //if(config.data[selected][key].value > longest)
          //longest = config.data[selected][key].value
          data.push(config.data[selected][key]);
          total += config.data[selected][key];
          //dataRight.push(config.data[selected][key].value);
          labels.push(key);
          labelsRight.push(config.data[selected][key]);
        }

        //we need to normalize the data
        //that means we need the left and right datasets to add up to the max width of the chart
        //and we also need the original left data set for the labels

        var ratio = Math.max.apply(Math, [data[0], data[1], total]) / 100,
          l = data.length,
          i;

        for (i = 0; i < l; i++) {
          //normalize left data
          var newLeft = Math.round(data[i] / ratio);
          dataLeft.push(newLeft);
          dataRight.push(Math.round(100 - newLeft));
        }

        //var maxAxesX = longest;
        //maxAxesX += (maxAxesX * .15); // for labels
        //console.log("Max X "+maxAxesX);

        //we need 2 datasets - 1 with the actual values, 1 with values up to maxAxes
        //for(let item of data){
        //console.log(item);
        //  dataRight.push(Math.round(150 - item));
        //}

        // Create new Chart instance.
        instance = new window.Chart(canvas, {
          type: 'horizontalBar',
          plugins: [window.ChartDataLabels],
          // The data for our dataset
          data: {
            labels: labels,
            originalData: dataLeft,
            datasets: [
              {
                data: dataLeft,
                backgroundColor: config.colors,
                borderWidth: 0,
                datalabels: {
                  display: false
                }
              },
              {
                data: dataRight,
                backgroundColor: '#f1f1f1',
                datalabels: {
                  display: true,
                  anchor: 'end',
                  align: 'start',
                  formatter: function(value, context) {
                    var this_data = context.chart.data.originalData;
                    //console.log(this_data);
                    var this_str = this_data[context.dataIndex];
                    //console.log(this_str);
                    var str = this_str + '%';
                    return String(str).toUpperCase();
                  },
                  padding: {
                    right: 6,
                    left: 0
                  },
                  color: '#4d4d4d',
                  font: {
                    family: "'DIN',sans-serif",
                    size: 20,
                    weight: 500
                  }
                }
              }
            ]
          },

          // Configuration options go here
          options: {
            plugins: {
              // Change options for ALL labels of THIS CHART
              datalabels: {
                display: false
              }
            },
            responsive: false,
            maintainAspectRatio: true,
            padding: {
              // Any unspecified dimensions are assumed to be 0
              //left: 100
              //right: -100
            },
            scales: {
              xAxes: [
                {
                  //afterFit: function(scaleInstance) {
                  //  scaleInstance.width = ; // sets the width to 100px
                  //},
                  maxBarThickness: 200,
                  stacked: true,
                  display: false,
                  beginAtZero: true,
                  ticks: {
                    min: 0,
                    max: 100 // maxAxesX
                  }
                }
              ],
              yAxes: [
                {
                  stacked: true,
                  display: false,
                  barPercentage: 0.65,
                  categoryPercentage: 1
                }
              ]
            }
            /* legendCallback: function (chart) {
                  var text = [],
                    i;

                  text.push('<div class="c-chart__legend-inner"><ul class="c-chart__legend-items">');

                  for (i = 0; i < legend.length; i++) {
                    text.push('<li class="c-chart__legend-item" data-label="' + labels[i] + '">');
                    text.push('<span class="c-chart__legend-item-label">' + labels[i] + '</span>');
                    text.push('<span class="c-chart__legend-item-value">0</span>');
                    text.push('</li>');
                  }

                  text.push('</ul></div>');

                  return text.join('');
                },*/
            /*
                events: false,
                animation: {
                  onComplete: function (animation) {
                    var chartInstance = animation.chart,
                      ctx = chartInstance.ctx;
                    // fontSize      = 7;

                    ctx.font = window.Chart.helpers.fontString(9, 700, window.Chart.defaults.global.defaultFontFamily);
                    ctx.fillStyle = '#4e4e4e';
                    ctx.textBaseline = 'top';

                    chartInstance.data.datasets.forEach(function (dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);

                      meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        // x    = 5;

                        // if ( data < 15 ) {
                        //   return;
                        // }

                        // if ( ! animation.animationObject.numSteps ) {

                        //   if ( ( typeof( config.extra ) === 'object' ) && true === config.extra.in_percent ) {
                        //     data += '%';
                        //   }

                        //   ctx.textAlign = 'end';
                        //   ctx.fillText( data, bar._model.x - 15, bar._model.y );
                        // }

                        // if ( data < 40 ) {
                        //   ctx.fillStyle = '#000';
                        //   ctx.textBaseline = 'top';
                        //   x = bar._model.x + 5;
                        // } else {
                        //   ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                        //   ctx.textBaseline = 'middle';
                        // }

                        var l = labels[index];

                        ctx.textAlign = 'start';
                        ctx.fillText(l.toUpperCase(), bar._model.x + 5, bar._model.y);
                      });
                    });
                  },
                  onProgress: function (animation) {
                    var chartInstance = animation.chart,
                      ctx = chartInstance.ctx,
                      progress = 1;

                    if (animation.animationObject.numSteps) {
                      progress = animation.animationObject.currentStep / animation.animationObject.numSteps;
                    }

                    ctx.font = window.Chart.helpers.fontString(9, 700, window.Chart.defaults.global.defaultFontFamily);
                    ctx.fillStyle = '#000';
                    ctx.textAlign = 'start';
                    ctx.textBaseline = 'bottom';

                    chartInstance.data.datasets.forEach(function (dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);

                      meta.data.forEach(function (bar, index) {
                        var data = Math.round(dataset.data[index] * progress),
                          _data = dataset.data[index];
                        // x     = bar._model.x - 15;

                        // if ( _data < 40 ) {
                        //   ctx.fillStyle = '#000';
                        //   ctx.textAlign = 'start';
                        //   ctx.textBaseline = 'bottom';
                        //   x = bar._model.x + 5;
                        // } else {
                        //   ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                        //   ctx.textAlign = 'end';
                        //   ctx.textBaseline = 'middle';
                        // }

                        if ((typeof (config.extra) === 'object') && true === config.extra.in_percent) {
                          data += '%';
                        }

                        ctx.fillText(data, bar._model.x + 5, bar._model.y);
                      });
                    });
                  }
                }*/
          }
        });

        // Generate legend.
        //$chart.find('.c-chart__legend').html(instance.generateLegend());

        /*
            $chart.find('.c-chart__legend-item').each(function (i, bar) {
              var $bar = $(bar);

              $bar.css({
                color: config.colors[i],
                visibility: 'visible'
              });

              animateChartCounter($bar.find('.c-chart__legend-item-value'), legend[i]);
            });
            */
        $select.on('change', function(event) {
          var $target = $(event.currentTarget),
            selected = $target.find('option:selected').val(),
            data = [],
            dataLeft = [],
            dataRight = [],
            legend = [],
            total = 0,
            key;

          event.preventDefault();

          var longest = 0;
          // Prepare data and labels.
          for (key in config.data[selected]) {
            //if(config.data[selected][key].value > longest)
            //longest = config.data[selected][key].value
            data.push(config.data[selected][key]);
            total += config.data[selected][key];
            //dataRight.push(config.data[selected][key].value);
            //labels.push(key);
            //labelsRight.push(config.data[selected][key]);
          }

          //we need to normalize the data
          //that means we need the left and right datasets to add up to the max width of the chart
          //and we also need the original left data set for the labels

          var ratio = Math.max.apply(Math, [data[0], data[1], total]) / 100,
            l = data.length,
            i;

          for (i = 0; i < l; i++) {
            //normalize left data
            var newLeft = Math.round(data[i] / ratio);
            dataLeft.push(newLeft);
            dataRight.push(Math.round(100 - newLeft));
          }
          console.log('our new data left');
          console.log(dataLeft);
          console.log('our new data right');
          console.log(dataRight);
          // Update data.
          instance.data.originalData = dataLeft;
          instance.data.datasets[0].data = dataLeft;
          instance.data.datasets[1].data = dataRight;
          //instance.data.labels = labels;
          instance.update();
        });
      });

      // Top 5 Concepts.
      $('#hilton_top_countries').on('change', function(event) {
        var $target = $(event.currentTarget),
          selected = $target.find('option:selected').val(),
          $chart = $('.c-chart[data-filter="hilton_top_countries"]'),
          $col_inprogress = $('.o-table__body-column-in-progress'),
          $col_open = $('.o-table__body-column-open'),
          visible_counter;

        event.preventDefault();

        $chart.find('.o-table__body').addClass('u-hidden');
        $chart.find('.o-table__body.' + selected).removeClass('u-hidden');
      });

      function animateChartCounter(selector, value, threshold) {
        if (typeof threshold === 'undefined') {
          threshold = false;
        }

        if (typeof selector.prop('Counter') === 'undefined') {
          selector.prop('Counter', 0);
        }

        selector.animate(
          {
            Counter: value
          },
          {
            duration: 800,
            easing: 'swing',
            step: function(now) {
              var _now = Math.ceil(now);
              var _text = _now;

              if (false !== threshold) {
                _text = ('0' + _now).slice(-1 * threshold);
              }

              $(this)
                .text(_text + ($(this).hasClass('in-percent') ? '%' : ''))
                .prop('Counter', now);
            }
          }
        );
      }

      function animateChartBar(selector, value, threshold) {
        if (typeof threshold === 'undefined') {
          threshold = false;
        }

        if (typeof selector.prop('Counter') === 'undefined') {
          selector.prop('Counter', 0);
        }

        selector.animate(
          {
            Counter: value
          },
          {
            duration: 800,
            easing: 'swing',
            step: function(now) {
              var _now = now; //Math.ceil(now)
              var _text = _now;

              if (false !== threshold) {
                _text = ('0' + _now).slice(-1 * threshold);
              }

              // $(this)
              //   .css('width', _text + '%')
              //   .prop('Counter', now);
            }
          }
        );
      }

      function cutomWrapText(
        context,
        text,
        marginLeft,
        marginTop,
        maxWidth,
        lineHeight
      ) {
        var words = text.split(' '),
          countWords = words.length,
          line = '',
          n,
          testLine,
          testWidth;

        for (n = 0; n < countWords; n++) {
          testLine = line + words[n] + ' ';
          testWidth = context.measureText(testLine).width;

          if (testWidth > maxWidth) {
            context.fillText(line, marginLeft, marginTop);
            line = words[n] + ' ';
            marginTop += lineHeight;
          } else {
            line = testLine;
          }
        }

        context.fillText(line, marginLeft, marginTop);
      }
    }
  } // end dashboard_charts
};
