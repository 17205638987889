export var dashboardReports = {
    reporting: {
        init: function (namespace) {
            console.log('Dashboard Reports Init')
            var $reportForm = $('#create-report-form'),
                $chartsContainer = $('#custom-report-preview'),
                $preloader = $('body');
  
            $('.report__action-submit').click(function (event) {
                $reportForm.trigger('submit');
            });
  
            $('.report__action-back').click(function (event) {
                $chartsContainer.html('').hide();
                $reportForm.show();
                changeStep('back');
            });
  
            $('.report__action-new').click(function (event) {
                resetReportForm();
                $chartsContainer.html('').hide();
                $reportForm.show();
                changeStep(1);
            });
  
            $('.js-export-target').click(function (event) {
                //console.log('export clicked');
                $reportForm.find('#request_type').val('export');
                $reportForm.trigger('submit');
            });
  
            // Fire buttons events.
            namespace.buttons_action_events.init();
  
            $reportForm.ajaxForm({
                url: window.data.ajaxurl,
                type: 'POST',
                data: {
                    _ajax_nonce: window.data.nonce,
                },
                dataType: 'json',
                beforeSubmit: function (formData, jqForm, options) {
                    $preloader.loadingModal();
        
                    var type = $reportForm.find('#request_type').val(),
                        action;
        
                    if (type == 'load') {
        
                        action = 'hta_get_report_dashboard';
                        $('#custom-report-preview').html('');
        
                    } else if (type == 'save') {
  
                        action = 'hta_save_custom_report';
                        formData.push({
                            name: 'title',
                            value: $('.dashboard_charts .dashboard__section_label').text()
                        });
  
                    } else if (type == 'export') {
                        action = 'hta_export_to_excel';
            
                        formData.push({
                            name: 'process_count',
                            value: $('.js-export-target').data('process-count')
                        });
  
                    } else {
                        $preloader.loadingModal('destroy');
                        return false;
                    }
  
                    formData.push({
                        name: 'action',
                        value: action
                    });
  
                    return true;
                },
                success: function (responseText, statusText, xhr, $form) {
  
                    if (responseText.success) {
                        var type = $reportForm.find('#request_type').val();
  
                        if (type == 'load') {
  
                            $reportForm.hide();
                            $chartsContainer.html(responseText.data).show();
                            changeStep(2);
  
                            // Fire charts initialization.
                            namespace.dashboard_charts.init();
  
                            //setTimeout(disableNotUsageCharts, 100);
                            $preloader.loadingModal('destroy');
  
                        } else if (type == 'save') {
        
                            changeStep(3);
                            window.show_message(responseText.data);
                            $preloader.loadingModal('destroy');

                        } else if (type == 'export') {
                            //console.log('success: export');
                            //console.log(responseText.data);

                            // Send again...
                            if (-1 != responseText.data.process_count) {
                                $('.js-export-target').data('process-count', responseText.data.process_count);
                                $reportForm.trigger('submit');
            
                            } else {
            
                                if (responseText.data.hasOwnProperty('file')) {
                                    window.location = responseText.data.file.url;
                                }
            
                                if (responseText.data.hasOwnProperty('message')) {
                                    window.show_message(responseText.data.message, 'warning');
                                }
            
                                if (!$preloader.hasClass('single-export')) {
                                    $reportForm.find('#request_type').val('load');
                                }
            
                                $('.js-export-target').data('process-count', 0);
                                $preloader.loadingModal('destroy');
                            }
                        }
                    } else {
                        window.show_message(responseText.data, 'error');
                        $preloader.loadingModal('destroy');
                    }
                }   //Close success
            }); //Close AJAX form
  
            var changeStep = function (step) {
                var $requestType = $reportForm.find('#request_type'),
                    $nextStep = $('.step-' + step);
    
                if (step == 1 || step == 'back') {
                    $requestType.val('load');
                } else if (step == 2) {
                    $requestType.val('save');
                }
    
                $('.step').hide();
                $nextStep.show();
            }
  
            $('.report__action-reset').click(function (event) {
                event.preventDefault();
                resetReportForm();
            });
  
            var resetReportForm = function () {
                var $selects = $reportForm.find('select');

                $.each($selects, function (index, el) {
                    $(el).closest('.SumoSelect').find('.opt.default').trigger('click');
                });

                $reportForm.trigger('reset');
            }
            //unused?
            var addOverlay = function ($el) {
                // $el.css('background-color', 'rgba(0,0,0,0.5)');
                $el.remove();
            }

        } //end init
    } // end reporting
};