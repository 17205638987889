export var modalNotes = {
    note_modals: {
        init: function () {
            console.log('Note Modals Init');
            
            $('.note_modal').on('click', function () {
                $('#note_form').find('#attachment').attr('id', 'attachment-inactive');
  
                // using data-modal info, we set our note variable correctly
                var note = $(this).data('modal');
  
                // remove any other active classes
                $('.note-modal').removeClass('note-modal-active');
                window.scrollTo(0, 0);
                // make the one we want active, well active
                $('.note-modal[data-modal="' + note + '"]').addClass('note-modal-active');
            });
  
            $('#attachment-close').on('click', function () {
                $('#attachment-input-old').addClass('u-hidden');
                $('#attachment-input-new').removeClass('u-hidden');
                $('#attachment-close').addClass('u-hidden');
                $('#attachment_mode').val('update');
            });
  
            $('.note-modal-close').on('click', function () {
                $('#note_form').find('#attachment-inactive').attr('id', 'attachment');
                var node = $(this).data('modal');
                $('.note-modal').removeClass('note-modal-active');
            });
  
            $('#note_submit_update').on('click', function () {
                $('#note_mode').val('update');
            });
  
            $('#note_submit_delete').on('click', function () {
                $('#note_mode').val('delete');
            });
        }
    } // end note_modals
};