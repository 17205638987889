export var modalContact = {
    contact_modals: {
        init: function () {
            console.log('Contact Modal Init');
            
            $('.c-content-container #contact_country').change(function (event) {
                $('.c-content-container #country_phone_code').val($(this).find('option:selected').data('code'));
            });
  
            $('.contact-modal #contact_country').change(function (event) {
                $('.contact-modal #country_phone_code').val($(this).find('option:selected').data('code'));
            });
  
            // modal handling
            $('.contact_modal').on('click', function () {
                // using data-modal info, we set our contact variable correctly
                var contact = $(this).data('modal');
    
                // remove any other active classes
                $('.contact-modal').removeClass('contact-modal-active');
    
                // make the one we want active, well active
                $('.contact-modal[data-modal="' + contact + '"]').addClass('contact-modal-active');
            });
  
            $('.contact-modal-close').on('click', function () {
                var node = $(this).data('modal');
                $('.contact-modal').removeClass('contact-modal-active');
            });
  
            $('.contact_submit_update').on('click', function () {
                $('.contact_mode').val('update');
                // $(this).closest('.c-form').find('#contact_emails').prop('required', false);
                // $(this).closest('.c-form').find('#contact_first_name').prop('required', false);
                // $(this).closest('.c-form').submit();
            });
  
            $('.contact_submit_delete').on('click', function () {
                $('.contact_mode').val('delete');
                // $(this).closest('.c-form').find('#contact_emails').prop('required', false);
                // $(this).closest('.c-form').find('#contact_first_name').prop('required', false);
                // $(this).closest('.c-form').submit();
            });
  
            // $('.grant_access').on('click', function() {
            //   var form = $(this).closest('.c-form');
            //   form.find('#contact_emails').prop('required', true);
            //   form.find('#contact_first_name').prop('required', true);
            //   form.submit(
            //     function(e) {
            //       //console.log('hmm');
            //       var email = form.find('#contact_emails').val();
            //       var firstName = form.find('#contact_first_name').val();
            //       //console.log(email.trim() === "" || firstName.trim() === "");
            //       return !(email.trim() === "" || firstName.trim() === "");
            //     });
            // });
        }
    } // end contact_modals  
};