/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */


console.log('project details loading');
 export var projectDetails = {
        // All pages with body class 'example'    
        concepts_table: {
            init: function () {
            $('#concepts_table').cardtable();
            }
        }, // end concepts_table
        single_project: {
            init: function () {
              $('.js-spinner').hide();
      
              // project details
              $('.js-project-details').validate({
                rules: {
                  proj_number_concepts: {
                    number: true,
                    min: 0
                  },
                  _gotcha: {
                    maxlength: 0
                  }
                },
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorPlacement: function (error, element) {
                  error.insertAfter(element.next());
                },
                highlight: function (element, errorClass, validClass) {
                  $(element).addClass(errorClass).removeClass(validClass);
                  $(element).parent().addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                  $(element).removeClass(errorClass).addClass(validClass);
                  $(element).parent().removeClass(errorClass);
                },
                submitHandler: function (form) {
                  var form_data = $(form).serialize();
                  $.ajax({
                    url: window.data.ajaxurl,
                    method: 'get',
                    data: {
                      'action': 'hta_update_project_details',
                      '_ajax_nonce': window.window.data.nonce,
                      'project_data': form_data
                    },
                    beforeSend: function () {
                      $('.js-project-details .js-spinner').show();
                      $('.js-project-details__message').removeClass('c-alert--error').text();
                    },
                    success: function (response) {
                      $('.js-project-details .js-spinner').hide();
                      if (response !== '') {
                        $(window).scrollTop(0);
                        $('.js-project-details__message').addClass('c-alert--error is-visible').text(response).focus();
                      } else {
                        $('.js-project-details .js-success').removeClass('u-hidden');
                        $('.js-project-details .js-submit-btn').prop('disable', true);
                        setTimeout(function () {
                          window.location = $(form).find('input[name=redirect]').val();
                        }, 500);
                      }
                    }
                  });
                }
              });
      
      
              // prototype details - add new concept
              $('.js-new-concept').validate({
                rules: {
                  _gotcha: {
                    maxlength: 0
                  }
                },
                submitHandler: function (form) {
                  var form_data = $(form).serialize();
                  $.ajax({
                    url: window.data.ajaxurl,
                    method: 'get',
                    data: {
                      'action': 'hta_add_new_concept',
                      '_ajax_nonce': window.data.nonce,
                      'form_data': form_data
                    },
                    beforeSend: function () {
                      $('.js-new-concept .js-spinner').show();
                      $('.js-new-concept__message').removeClass('c-alert--error').text();
                    },
                    success: function (response) {
                      $('.js-new-concept .js-spinner').hide();
                      if (response) {
                        // redirect directly
                        window.location = response;
                      } else {
                        // no scroll needed, allety is right above the action button
                        $('.js-new-concept__message').text('An error occurred while adding the concept. Please try again.').addClass('c-alert--error is-visible');
                      }
                    }
                  });
                }
              });
      
              // concept details
              $('.js-concept-details').validate({
                rules: {
                  concept_name: {
                    required: true
                  },
                  concept_seat_count: {
                    number: true,
                    min: 0
                  },
                  _gotcha: {
                    maxlength: 0
                  }
                },
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorPlacement: function (error, element) {
                  if (element.hasClass('js-dropdown__item')) {
                    error.insertAfter(element.closest('.js-dropdown__items'));
                  } else {
                    error.insertAfter(element.next());
                  }
                },
                highlight: function (element, errorClass, validClass) {
                  $(element).addClass(errorClass).removeClass(validClass);
                  $(element).parent().addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                  $(element).removeClass(errorClass).addClass(validClass);
                  $(element).parent().removeClass(errorClass);
                },
                submitHandler: function (form) {
                  var form_data = $(form).serialize();
                  $.ajax({
                    url: window.data.ajaxurl,
                    method: 'get',
                    data: {
                      'action': 'hta_update_concept',
                      '_ajax_nonce': window.data.nonce,
                      'concept_data': form_data
                    },
                    beforeSend: function () {
                      $('.js-concept-details .js-spinner').show();
                      $('.js-concept-details__message').removeClass('c-alert--error').text();
                    },
                    success: function (response) {
                      $('.js-concept-details .js-spinner').hide();
                      if (response !== '') {
                        $('.js-concept-details .js-success').removeClass('u-hidden');
                        $('.js-concept-details .js-submit-btn').prop('disabled', true);
                        setTimeout(function () {
                          window.location = response;
                        }, 500);
                      } else {
                        $(window).scrollTop(0);
                        $('.js-concept-details__message').text('An error occurred while updating the concept. Please try again.').addClass('c-alert--error is-visible');
                      }
                    }
                  });
                }
              });
      
              // new concept - detail view
              $('.js-concept-new-detailed').validate({
                rules: {
                  concept_name: {
                    required: true
                  },
                  concept_seat_count: {
                    number: true,
                    min: 0
                  },
                  _gotcha: {
                    maxlength: 0
                  }
                },
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorPlacement: function (error, element) {
                  if (element.hasClass('js-dropdown__item')) {
                    error.insertAfter(element.closest('.js-dropdown__items'));
                  } else {
                    error.insertAfter(element.next());
                  }
                },
                highlight: function (element, errorClass, validClass) {
                  $(element).addClass(errorClass).removeClass(validClass);
                  $(element).parent().addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                  $(element).removeClass(errorClass).addClass(validClass);
                  $(element).parent().removeClass(errorClass);
                },
                submitHandler: function (form) {
                  var form_data = $(form).serialize();
      
                  $.ajax({
                    url: window.data.ajaxurl,
                    method: 'get',
                    data: {
                      'action': 'hta_new_concept_detailed',
                      '_ajax_nonce': window.data.nonce,
                      'concept_data': form_data
                    },
                    beforeSend: function () {
                      $('.js-spinner').show();
                      $('.js-concept-new-detailed__message').removeClass('c-alert--error').text();
                    },
                    success: function (response) {
                      $('.js-spinner').hide();
                      if (response !== '') {
                        $('.js-concept-details .js-success').removeClass('u-hidden');
                        $('.js-concept-details .js-submit-btn').prop('disabled', true);
                        setTimeout(function () {
                          window.location = response;
                        }, 500);
                      } else {
                        $(window).scrollTop(0);
                        $('.js-concept-new-detailed__message').text('An error occurred while adding the concept. Please try again.').addClass('c-alert--error is-visible');
                      }
                    }
                  });
                }
              });
      
              // contact details
              $('.js-contact-details').validate({
                rules: {
                  _gotcha: {
                    maxlength: 0
                  }
                },
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorPlacement: function (error, element) {
                  if (element.hasClass('js-dropdown__item')) {
                    error.insertAfter(element.closest('.js-dropdown__items'));
                  } else {
                    error.insertAfter(element.next());
                  }
                },
                highlight: function (element, errorClass, validClass) {
                  $(element).addClass(errorClass).removeClass(validClass);
                  $(element).parent().addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                  $(element).removeClass(errorClass).addClass(validClass);
                  $(element).parent().removeClass(errorClass);
                },
                submitHandler: function (form) {
                  var form_data = $(form).serialize();
                  $.ajax({
                    url: window.data.ajaxurl,
                    method: 'get',
                    data: {
                      'action': 'hta_update_contact',
                      '_ajax_nonce': window.data.nonce,
                      'contact_data': form_data
                    },
                    beforeSend: function () {
                      $('.js-spinner').show();
                      $('.js-contact-details__message').removeClass('c-alert--error').text('');
                    },
                    success: function (response) {
                      $('.js-spinner').hide();
                      if (response !== '') {
                        $('.js-contact-details .js-success').removeClass('u-hidden');
                        $('.js-contact-details .js-submit-btn').prop('disabled', true);
                        setTimeout(function () {
                          window.location = response;
                        }, 500);
                        // to do : if you don't wanna redirect, you might want to remove the emty email / phone fields.
                      } else {
                        $('.js-contact-details__message').addClass('c-alert--error is-visible').text('An error occurred while updating the contact. Please try again.');
                      }
                    }
                  });
                }
              });
      
              // contact new
              $('.js-contact-new-details').validate({
                rules: {
                  contact_email: {
                    email: true
                  },
                  _gotcha: {
                    maxlength: 0
                  }
                },
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorPlacement: function (error, element) {
                  if (element.hasClass('js-dropdown__item')) {
                    error.insertAfter(element.closest('.js-dropdown__items'));
                  } else {
                    error.insertAfter(element.next());
                  }
                },
                highlight: function (element, errorClass, validClass) {
                  $(element).addClass(errorClass).removeClass(validClass);
                  $(element).parent().addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                  $(element).removeClass(errorClass).addClass(validClass);
                  $(element).parent().removeClass(errorClass);
                },
                submitHandler: function (form) {
                  var form_data = $(form).serialize();
                  $.ajax({
                    url: window.data.ajaxurl,
                    method: 'get',
                    data: {
                      'action': 'hta_new_contact',
                      '_ajax_nonce': window.data.nonce,
                      'contact_data': form_data
                    },
                    beforeSend: function () {
                      $('js-contact-new-details .js-spinner').show();
                      $('.js-contact-details__message').removeClass('c-alert--error').text('');
                    },
                    success: function (response) {
                      $('.js-spinner').hide();
                      if (response !== '') {
                        $('.js-contact-new-details .js-success').removeClass('u-hidden');
                        $('.js-contact-new-details .js-submit-btn').prop('disabled', true);
                        setTimeout(function () {
                          window.location = response;
                        }, 500);
                      } else {
                        $(window).scrollTop(0);
                        $('.js-contact-new-details__message').addClass('c-alert--error is-visible').text('An error occurred while creating the contact. Please try again.');
                      }
                    }
                  });
                }
              });
      
              // delete concept
              $('body').on('click', '.js-delete-item', function (e) {
                e.preventDefault();
                var text = $(this).data('text');
                if (confirm(text)) {
                  var id = $(this).data('id');
                  var redirect = $(this).data('redirect');
                  var $link_message = $(this).parent().find('.js-message');
                  $.ajax({
                    url: window.data.ajaxurl,
                    method: 'post',
                    data: {
                      'action': 'hta_delete_item',
                      '_ajax_nonce': window.data.nonce,
                      'id': id
                    },
                    beforeSend: function () {
                      $link_message.removeClass('c-alert--success c-alert--error').text('');
                    },
                    success: function (response) {
                      if (response === 'success') {
                        $link_message.addClass('c-alert--success is-visible').text('Deletion successfull.');
                        setTimeout(function () {
                          window.location = redirect;
                        }, 500);
                      } else {
                        $link_message.addClass('c-alert--error is-visible').text('An error occurred while deleting the item. Please try again.');
                      }
                    }
                  });
                }
              });
            }
          }, // end single_project

          confirm_submit: {
            init: function () {
      
              function concept_box(message, callback) {
      
                var dfd = $.Deferred();
                var dialog = $("<div>", {
                  id: "confirm"
                })
                  .html(message)
                  .appendTo($("body"))
                  .data("selection", false)
                  .dialog({
                    closeText: "x",
                    autoOpen: false,
                    resizable: false,
                    title: 'Required',
                    zIndex: 99999999,
                    modal: true,
                    draggable: false,
                    buttons: [{
                      text: "Close",
                      click: function () {
                        $(this).dialog("close");
                        dfd.resolve(false);
                      }
                    }],
                    close: function (event, ui) {
                      $('#confirm').remove();
                    },
                    open: function () {
                      $(this).closest(".ui-dialog")
                        .find(".ui-dialog-titlebar-close")
                        .addClass("note-modal-close")
                        .html("<svg style='left:0px;' class='o-icon c-note-icon'><use xlink:href='#icon-close'></use></svg>");
                    }
                  });
                dialog.dialog("open");
                return dfd.promise();
              }
      
              function ui_confirm(message, callback, opts) {
      
                if (opts === undefined) {
                  opts = {
                    buttons: [{
                      text: "Yes",
                      class: '',
                    }, {
                      text: "No",
                      class: '',
                    }]
                  };
                }
      
                var dfd = $.Deferred();
                var dialog = $("<div>", {
                  id: "confirm"
                })
                  .html(message)
                  .appendTo($("body"))
                  .data("selection", false)
                  .dialog({
                    closeText: "x",
                    autoOpen: false,
                    resizable: false,
                    title: 'Confirm',
                    zIndex: 99999999,
                    modal: true,
                    draggable: false,
                    minWidth: 310,
                    buttons: [{
                      text: opts.buttons[0].text,
                      class: opts.buttons[0].class,
                      click: function () {
                        $(this).dialog("close");
                        dfd.resolve(true);
                        if ($.isFunction(callback)) {
                          callback.apply();
                        }
                      }
                    }, {
                      text: opts.buttons[1].text,
                      class: opts.buttons[1].class,
                      click: function () {
                        $(this).dialog("close");
                        dfd.resolve(false);
                      }
                    }],
                    close: function (event, ui) {
                      $('#confirm').remove();
                    },
                    open: function () {
                      $(this).closest(".ui-dialog")
                        .find(".ui-dialog-titlebar-close")
                        .addClass("note-modal-close")
                        .html("<svg style='left:0px;' class='o-icon c-note-icon'><use xlink:href='#icon-close'></use></svg>");
                    }
                  });
                dialog.dialog("open");
                return dfd.promise();
              }
      
              $(function () {
      
                $('#myForm').validate({
                  rules: {
                    prop_project_lead: {
                      required: true
                    },
                    'prop_target_open_date-display' : {
                      required: true
                    },
                  },
                  errorClass: 'is-invalid',
                  validClass: 'is-valid',
                  errorPlacement: function (error, element) {
                    if (element.hasClass('js-dropdown__item')) {
                      error.insertAfter(element.closest('.js-dropdown__items'));
                    } else {
                      error.insertAfter(element.next());
                    }
                  },
                  highlight: function (element, errorClass, validClass) {
                    $(element).addClass(errorClass).removeClass(validClass);
                    $(element).parent().addClass(errorClass);
                  },
                  unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass(errorClass).addClass(validClass);
                    $(element).parent().removeClass(errorClass);
                  },
                  submitHandler: function (form) {
      
                    //var unchosenConcepts = $('.concept_select').toArray().filter(function (element) {
                      // console.log($(element).find('select').val());
                    //  return !($(element).find('select').val());
                    //});
      
                    //if (unchosenConcepts.length !== 0) {
                    //  $.when(concept_box("Fill out or remove any unselected concept boxes."));
                    //}
                    //else {
                      //$.when(ui_confirm("Are you sure?")).done(function (val) {
                      //  if (val) {
                          $('#myForm')[0].submit();
                       // }
                      //});
                    //}
                  }
                });
      
              });
      
      
              var isFocus = false;
              $('.propery-details-section .confirmed').click(function (event) {
                var ths = $(this);
                var disabled = $(this).is(":disabled");
                if (isFocus == false && !disabled) {
                  $(this).blur();
                  $.when(ui_confirm("Are you sure you want to edit this field?")).done(function (val) {
                    if (val) {
                      ths.focus();
                      isFocus = true;
                    }
                  });
                }
              });
      
      
              $('.propery-details-section .confirmed').keyup(function (e) {
                var code = e.keyCode || e.which;
                if (code == '9') {
                  var ths = $(this);
                  var disabled = $(this).is(":disabled");
                  if (isFocus == false && !disabled) {
                    $(this).blur();
                    $.when(ui_confirm("Are you sure you want to edit this field?")).done(function (val) {
                      if (val) {
                        ths.focus();
                        isFocus = true;
                      }
                    });
                  }
                }
              });
      
              $('.propery-details-section .confirmed + .select2').click(function (event) {
                var ths = $(this).prev();
                ths.select2('close');
                var disabled = ths.is(":disabled");
                if (isFocus == false && !disabled) {
                  ths.blur();
                  $.when(ui_confirm("Are you sure you want to edit this field?")).done(function (val) {
                    if (val) {
                      ths.select2('open');
                    }
                  });
                }
              });
      
              $('.propery-details-section .confirmed + .select2 .select2-selection').keyup(function (e) {
                var code = e.keyCode || e.which;
                if (code == '9') {
                  var ths = $(this).parents('.select2').prev();
                  ths.select2('close');
                  var disabled = ths.is(":disabled");
                  if (isFocus == false && !disabled) {
                    ths.blur();
                    $.when(ui_confirm("Are you sure you want to edit this field?")).done(function (val) {
                      if (val) {
                        ths.select2('open');
                      }
                    });
                  }
                }
              });
      
              $('.propery-details-section .confirmed').focusout(function (event) {
                isFocus = false;
              });
      
              $('.propery-details-section select.confirmed').on('select2:close', function (event) {
                isFocus = false;
              });
      
              $( '#js-delete-project' ).on( 'click', function( event ) {
                event.preventDefault();
                  var $btn = $( event.currentTarget );
                  $.when( window.ui_confirm( "Are you sure you want to delete this project? This cannot be undone.", false, {
                    buttons: [{
                      text: "Delete",
                      class: "ui-button--danger"
                    }, {
                      text: "Cancel"
                    }]
                  } ) ).done( function( confirm ) {
                    if ( confirm ) {
                      $.ajax( {
                        url: window.data.ajaxurl,
                        method: 'post',
                        data: {
                          'action': 'hta_delete_property_and_project',
                          '_ajax_nonce': window.data.nonce,
                          'proj_id': $btn.data( 'project-id' ),
                          'prop_id': $btn.data( 'property-id' ),
                        },
                        success: function ( response ) {
                          if ( response.success ) {
                            window.location.replace( response.data );
                          }
                        }
                      });
                    }
                  } );
              } );
      
            }
          }, // end confirm_submit
    };
