// New Property Page

export var newProperty = {
    new_property: {
        init: function () {
  
            $('#prop_target_open_date').prop('min', function () {
                return new Date().toJSON().split('T')[0];
            });
            $('#prop_target_open_date').removeAttr('readonly');
  
            $('#prop_target_open_date').on('change', function () {
  
                var date = $(this).val();
    
                // translates date value into whatever the browser on hand needs for proper validation
                $(this).val(new Date(date).toJSON().split('T')[0]);
    
                // keeps projected year open updated in case this value is needed for reports or other parts of the site
                $('input#prop_projected_year_open').val((new Date(date)).getFullYear());
            });
  
            $('.js-spinner').hide();

            $('.js-property-new').validate({
                rules: {
                    'prop_target_open_date-display': {
                        required: true,
                    },
                    prop_end_code: {
                        required: true
                    },
                    prop_region: {
                        required: true
                    },
                    prop_brand: {
                        required: true
                    },
                    prop_project_type: {
                        required: true
                    },
                    prop_projected_year_open: {
                        required: true
                    },
                    prop_ownership: {
                        required: true
                    },
                    prop_property_status: {
                        required: true
                    },
                    prop_project_lead: {
                        required: true
                    },
                    _gotcha: {
                        maxlength: 0
                    }
                },
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorPlacement: function (error, element) {
                    if (element.hasClass('js-dropdown__item')) {
                        error.insertAfter(element.closest('.js-dropdown__items'));
                    } else {
                        error.insertAfter(element.next());
                    }
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass(errorClass).removeClass(validClass);
                    $(element).parent().addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass(errorClass).addClass(validClass);
                    $(element).parent().removeClass(errorClass);
                },
                submitHandler: function (form) {
                    var form_data = $(form).serialize();
                    $.ajax({
                        url: window.data.ajaxurl,
                        method: 'get',
                        data: {
                            'action': 'hta_create_property_and_project',
                            '_ajax_nonce': window.data.nonce,
                            'property_data': form_data
                        },
                        beforeSend: function () {
                            $('.js-spinner').show();
                            $('.js-property-new__message').removeClass('c-alert--error').empty();
                        },
                        success: function (response) {
                            $('.js-spinner').hide();
                            if (response !== '') {
                                $('.js-submit-btn').prop('disabled', 'disabled');
                                $('.js-success').removeClass('u-hidden');
                                setTimeout(function () {
                                window.location = response;
                                }, 500);
                            } else {
                                $(window).scrollTop(0);
                                $('.js-property-new__message').text('An error occurred while creating the property. Please try again.').addClass('c-alert--error is-visible');
                            }
                        }
                    }); //End Ajax Handler
                } //End Submit Handler
            }); //End Validator
        } //End Init
    } // end new_property
};