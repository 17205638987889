// import external dependencies
import 'jquery'

import svg4everybody from 'svg4everybody'
svg4everybody()

//import 'select2'

// import local dependencies
import './modules'
import './components/concept-list'
/*
$('.js-select').select2({
  width: '100%'
})
*/
import { oldMain } from './old/_oldmain.js'
import { conceptDetails } from './old/_conceptDetails.js'
import { projectDetails } from './old/_projectDetails.js'
import { propertySearch } from './old/_propertySearch.js'
import { newProperty } from './old/_newProperty.js'
import { newProject } from './old/_newProject.js'
import { dashboardInit } from './old/_dashboardInit.js'
import { dashboardCharts } from './old/_dashboardCharts.js'
import { dashboardReports } from './old/_dashboardReports.js'
import { dashboardSavedReports } from './old/_dashboardSavedReports.js'
import { dashboardReportHandler } from './old/_dashboardReportHandler.js'
import { dashboardButtons } from './old/_dashboardButtons.js'
import { modalNotes } from './old/_modalNotes.js'
import { modalContact } from './old/_modalContact.js'

var fwd;
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  fwd = $.extend(
    oldMain,
    conceptDetails,
    projectDetails,
    propertySearch,
    newProperty,
    newProject,
    dashboardInit,
    dashboardCharts,
    dashboardReports,
    dashboardSavedReports,
    dashboardReportHandler,
    dashboardButtons,
    modalNotes,
    modalContact
  );
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      //console.log('firing something');
      var namespace = fwd;
      funcname = (funcname === undefined) ? 'init' : funcname;
      if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
        namespace[func][funcname](namespace); //pass the namespace as an optional param in case we need to reference other funcs
      }
    },
    loadEvents: function () {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
      });
    }
  };

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
