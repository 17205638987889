import { oldMain } from "./_oldmain";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

   console.log('concept details loading');
 export var conceptDetails = {
        // All pages with body class 'example'
        toggle_measurement: {
          init: function () {
    
            var precision = 1;
            var convFtMt = 0.092903;
    
            var fToM = function (measure) {
              if (typeof measure === 'number') {
                return (measure * convFtMt).toFixed(precision);
              }
              else {
                return '';
              }
            }
    
            var mToF = function (measure) {
              if (typeof measure === 'number') {
                return (measure / convFtMt).toFixed(precision);
              }
              else {
                return '';
              }
            }
    
    
            $(document).ready(function () {
              $('.measurement').on('change', function () {
                // Get our size field
                var sizeField = $(this).parents('.size-section').find('input.size');
                // Get the unit of measurement we're converting from
                var initMeasurement = $(sizeField).attr('data-measurement');
                // Get the unit of measurement we're converting to
                var currMeasurement = $(this).val();
                // Get the value of the current size
                var size = $(sizeField).val();
                // Make sure size is a float
                var validSize = !isNaN(parseFloat(size));
    
                if (validSize) {
                  var parsedVal = parseFloat(size);
                  var newsize; 
                  // update size appropriately based on from and to
                  if (initMeasurement === 'feet' && currMeasurement === 'meters') {
                    newsize = fToM(parsedVal);
                    $(sizeField).val(newsize);
                  }
                  else if (initMeasurement === 'meters' && currMeasurement === 'feet') {
                    newsize = mToF(parsedVal);
                    $(sizeField).val(newsize);
                  }
                }
    
                // Update current size field with new measurement
                $(sizeField).attr('data-measurement',currMeasurement);
              });
            });
          }
        }, // end toggle_measurement
    
    
        seatings: {
          init: function () {
            console.log('seatings init');
            
            window.ui_confirm = function (message, callback) {
    
              var dfd = $.Deferred();
              var dialog = $("<div>", {
                id: "confirm"
              })
                .html(message)
                .appendTo($("body"))
                .data("selection", false)
                .dialog({
                  closeText: "x",
                  autoOpen: false,
                  resizable: false,
                  title: 'Confirm',
                  zIndex: 99999999,
                  modal: true,
                  draggable: false,
                  buttons: [{
                    text: "Yes",
                    click: function () {
                      $(this).dialog("close");
                      dfd.resolve(true);
                      if ($.isFunction(callback)) {
                        callback.apply();
                      }
                    }
                  }, {
                    text: "No",
                    click: function () {
                      $(this).dialog("close");
                      dfd.resolve(false);
                    }
                  }],
                  close: function (event, ui) {
                    $('#confirm').remove();
                  },
                  open: function () {
                    $(this).closest(".ui-dialog")
                      .addClass('confirm-dialog')
                      .find(".ui-dialog-titlebar-close")
                      .addClass("note-modal-close")
                      .html("<svg style='left:0px;' class='o-icon c-note-icon'><use xlink:href='"+window.data.themeBase+"images/icons.svg#icon-close'></use></svg>");
                  }
                });
              dialog.dialog("open");
              return dfd.promise();
            }
    
            $('.seat-count').change(function(){
              var $parent = $(this).closest('.seatings-section');
              console.log('seat count has changed');
              console.log($parent);

              var seats = 0;
              $parent.find('.seating-container').not('.new_seating').find('input.seat-count').each(function(){
                seats += Number($(this).val());
              });
              $parent.find('.total-seats').text(seats);

            });
    
    
            $('.add_seating_style').on('click', function (e) {
              e.preventDefault();
              console.log('adding seating style');
              var $parent = $(this).closest('.seatings-section');
              
              console.log('parent:');
              console.log($parent);
              
              $parent.find('div.new_seating').find('select').select2('destroy');
    
              var seatings_count = $parent.find('.seating-container').length;
              //Get value of current new concepts (new concepts array indexed from 0)
              console.log('num seats');
              
              var new_seatings = parseInt($parent.find('[id*="\\[num_seatings_add\\]"]').val());
              console.log(new_seatings);
              
              var newRow = $parent.find('.new_seating').clone(true, true);

              newRow.find('input, select').each(function(){
                var new_id = $(this).attr('id').replace('[new]','['+new_seatings+']').replace('seatings_existing','seatings_new');
                console.log('our new id');
                console.log(new_id);

                $(this)
                .attr('name', new_id)
                .attr('id', new_id);
              });

              newRow.find('input.room').val('Room ' + seatings_count );
    
              // need to remove new_seating to make sure the hidden base element stays unaffected
              newRow.removeClass('new_seating u-hidden').insertBefore($parent.find('div.new_seating'));
              newRow.find('.seat-count').trigger('change');
              // disable searching through select options
              // disable searching through select options
              $parent.find('[id*="\\[num_seatings_add\\]"]').val(new_seatings+1);

              $parent.find('div.new_seating select').select2({minimumResultsForSearch: -1,width:'100%'});
              newRow.find('select').select2({minimumResultsForSearch: -1,width:'100%'});
            });
    
            $('.remove_seating').on('click', function (e) {
              // on click was causing form submission, hence why e.preventDefault() is called
              // this is strange behavior because the remove_seating_new button is not of type "submit"
              e.preventDefault();
              $(this).closest('.seating-container').find('.seat-count').val(0).trigger('change');
              $(this).closest('.seating-container').find('select').select2('destroy');
              $(this).closest('.seating-container').remove();
            });
    
            $('.seating_new .seating_new_room').focus(function() {
              $(this).select();
            });
    
    
          }
        }, // end seatings
        
        select_type_filter: {
          init: function () {
            $('.agreement-select select').each(function(){
              console.log('we have an agreement select');
              $(this).on('change', function () {
                var fee = $(this).find('option:selected').val();
                console.log('changing agreement select');
                console.log(fee);
                if(fee === 'Partner' || fee === 'Management Agreement'){
                  $(this).closest('.details-section').find('.partner-fee-select').show();
                }
                else 
                  $(this).closest('.details-section').find('.partner-fee-select').hide();
              });
            });
            //New - init each panel
            $('.c-concept-list__panel .details-section').each(function(){
              var $type       = $(this).find('.concept-type'),
                  $proto      = $(this).find('.prototype option:selected'),
                  $category   = $(this).find('.concept-category'),
                  $nickname   = $(this).find('.nickname'),
                  $options    = $($type).find('option'),
                  id          = $category.find(':selected').data('id'),
                  $newOptions = $options.filter( function() {
                    return $( this ).data( 'parent' ) === id;
                  } ),
                  $fields = $(this).find('.c-form-field');
            
              $type.empty().append( $newOptions );
              
              if ( $newOptions.length ) {
                $type.attr( 'disabled', false );
      
              } else {
                $type.attr( 'disabled', true );
              }

              if ('202' === $proto.val() ){
                console.log('these fields should be disabled'); 
                console.log($fields);
                $fields.attr( 'disabled', true );
              }

              else if ( 'tbd' === $category.val() ) {
                $fields.attr( 'disabled', true );
                $category.attr( 'disabled', false );
                $nickname.attr( 'disabled', false );
      
              } else {
                $fields.attr( 'disabled', false );
              }

              $(this).find('.prototype').on('change',function(e){
                console.log('firing prototype change');

                var $fields   = $(this).closest('.details-section').find('.c-form-field'),
                    $category = $(this).closest('.details-section').find('.concept-category'),
                    $agreement  = $(this).closest('.details-section').find('.agreement-select'),
                    $feeName    = $(this).closest('.details-section').find('.fee-name-input');

                if ( '202' === $( this ).find('option:selected').val() ) {
                  $fields.attr( 'disabled', true );
                  $category.attr( 'disabled', true );
                } else {  
                  $category.attr('disabled',false);
                  $category.trigger('change');
                }

                if ('69' === $( this ).find('option:selected').val() ){
                  $feeName.show();
                  $agreement.show();
                }
                else
                {
                  $feeName.hide();
                  $agreement.hide();
                }


              });

              $(this).find('.concept-category').on( 'change', function( event ) {

                console.log('firing concept category change');
                
                var id        = $( this ).find( ':selected' ).data( 'id' ),
                    $fields   = $(this).closest('.details-section').find('.c-form-field'),
                    $nickname = $(this).closest('.details-section').find('.nickname'),
                    $type       = $(this).closest('.details-section').find('.concept-type'),
                   // $options    = $($type).find('option'),
                    $newOptions;
      
                event.preventDefault();
      
                if (id === undefined) {
                  $type.val([]).trigger('change');
                  $newOptions = [];
                }
                else {
                  $newOptions = $options.filter(function () {
                    return $(this).data("parent") === id;
                  });
                }
               
                $type.empty().append($newOptions);
                
                if ( $newOptions.length ) {
                  $type.attr( 'disabled', false );
      
                } else {
                  $type.attr( 'disabled', true );
                }
      
                if ( 'tbd' === $( this ).val() ) {
                  $fields.attr( 'disabled', true );
                  $( event.currentTarget ).attr( 'disabled', false );
                  $nickname.attr( 'disabled', false );
      
                } else {
                  $fields.attr( 'disabled', false );
                }
      
              } );

            });

            
    /*
            if ($('#concept-type').val() === 'other') {
              $type.attr('disabled', false);
              $('#concept-type-input').show();
            }
    
            $('#concept-type').on('change', function () {
              var val = $(this).find(':selected').val();
              var $auxInput = $('#concept-type-input');
    
              if (val === 'other') {
                $auxInput.show();
              } else {
                $auxInput.removeAttr('value');
                $auxInput.hide();
              }
            }) */
          }
        }, // end select_type_filter


      other_detail_selection: {
        init: function () {
          console.log('other details section');
          $('.concept-type').on('change', function (val) {
            console.log('concept type was changed');
            var selected = $(this).find(":selected").toArray();
            var $other = $(this).parents('.details-section').find('.other-input');
            console.log('other');
            console.log($other);
            console.log('selected');
            console.log(selected);
            if (selected.length > 0) {
              var foundOther = false;
              selected.forEach(function (element) {
                // if we find other, make sure the input box is visible
                if (element.label === "Other") {
                  foundOther = true;
                }
              });
              if (foundOther) {
                $other.removeClass('u-hidden');
              }
              else if (!$other.hasClass('u-hidden')) {
                console.log('other not hidden');
                $other.addClass('u-hidden');
                $other.find('input').val('');
              }
            }
          }).trigger('change');
          ;
        }
      }, // end other_detail_selection
  
      //NO LONGER USED??
      partner_checkbox: {
        init: function () {
          $("#prop-partner-box").on('change', function () {
            if (this.checked) {
              if (!$('#yes').hasClass('u-hidden')) {
                $('#yes-text').addClass('u-hidden');
              }
              $('#partner_name').removeClass('u-hidden');
            }
            else if (!$('#partner_name').hasClass('u-hidden')) {
              $('#partner_name').val('').addClass('u-hidden');
              $('#yes-text').removeClass('u-hidden');
  
            }
          }).trigger('change');
        }
      }, // end partner_checkbox

      dashboard_details: {
        init: function () {
          
          

          
  
  /*
          var disabledConcept = $('#disabled_concept').val();
          // console.log(disabledConcept);
          if (disabledConcept == 'true') {
            $('#concept_details_form .c-form__field')
              .not('#size, #measurement')
              .each(function (index, el) {
                $(this).attr('disabled', 'disabled');
              });
  
            $('#concept_details_form button')
              .not('.js-submit-btn')
              .each(function (index, el) {
                $(this).attr('disabled', 'disabled');
                $(this).css('pointer-events', 'none');
              });
          }
  
          // SEATING STYLE fileds group validation.
          $('.js-submit-btn').on('click', conceptDetailsUpdated);
          function conceptDetailsUpdated(event) {
            var $this = $(this),
              $form = $this.closest('form'),
              result = true;
  
            result = seatingStyleValidation($form);
  
            if (!result) {
              return result;
            }
  
            
  
            return result;
          }
  
          function seatingStyleValidation($form) {
            var $seatingNew = $form.find('.seating_new:visible')
            result = true;
  
            if (!$seatingNew.length) {
              return result;
            }
  
            $seatingNew.each(function (index, group) {
              var $group = $(group),
                $styleSelectWrap = $group.find('.c-form__group--select'),
                $styleSelectField = $styleSelectWrap.find('select'),
                $styleSelectError = $styleSelectWrap.find('.is-invalid');
  
              if ('' === $styleSelectField.val()) {
                $styleSelectError.removeClass('u-hidden');
                result = false;
                return !1;
  
              } else {
                $styleSelectError.addClass('u-hidden');
              }
            });
  
            return result;
          }
  
          
  
          */
  
        }
      }, // end dashboard_details

      concept_accordion: {
        init: function() {

          //Init accordions
          var $conceptListPanel = $('.js-concept-list-panel');
          var $conceptListOpen = $('.js-concept-list-open');
          var $conceptListClose = $('.js-concept-list-close');

          //$conceptListPanel.hide()

          $('.js-concept-list-open').on('click', function(e) {
            e.preventDefault();
            console.log('clicked open');
            $(this)
              .parents('.c-concept-list__row')
              .next('.js-concept-list-panel')
              .slideDown();
          })

          $('.js-concept-list-close').on('click', function(e) {
            e.preventDefault();
            //Need to update the previous item's labels
            //Concept name, Nickname, Category:type:other,Meal Periods,Size,Seats,Status,Date
            var $panel = $(this).closest('.js-concept-list-panel');

            var concept_name = $panel.find('select.prototype option:selected').text();
            var nickname = $panel.find('input.nickname').val();
            var concept_category = $panel.find('select.concept-category option:selected').text();
            var concept_types = '';
            $panel.find('select.concept-type option:selected').each(function(){
              if($(this).text() != '')
                concept_types += (concept_types !=''?', ':'') + $(this).text();
            });
            var other = $panel.find('input.other').val();
            var meals = '';
            $panel.find('select.meal-period option:selected').each(function(){
              if(meals == '')
                meals = $(this).text();
              else
                meals += ', ' + $(this).text();
            });
            var projected_opening = $panel.find('input.projected-opening').val();
            var size = $panel.find('input.size').val();
            var measurement = $panel.find('input.size').val();
            if(measurement == 'feet')
              size += " FT<sup>2</sup>";
            else if(measurement == "meters")
              size += " M<sup>2</sup>";

            var concept_status = $panel.find('select.concept-status option:selected').text();
            var seats = 0;
            $panel.find('.seating-container').not('.new_seating').find('input.seat-count').each(function(){
              seats += Number($(this).val());
            });

            var data_array = [
              concept_name.replace(/\s+/g, " "),
              nickname.replace(/\s+/g, " "),
              (concept_category + (concept_types != ''?': ':'') + concept_types + ((other != '') ? ' ' + other : '' )).replace(/\s+/g, " "),
              meals.replace(/\s+/g, " "),
              size.replace(/\s+/g, " "),
              seats,
              concept_status.replace(/\s+/g, " "),
              projected_opening.replace(/\s+/g, " "),
            ];
            console.log(data_array);

            $panel
              .slideUp(400,function(){
                $(this).prev('.c-concept-list__row').find('.c-concept-list__item').each(function(i){
                  console.log(data_array[i]);
                  $(this).html(data_array[i]);
                });
              });
          })

          //Add new concept button
          $('.c-concept-list__add').on('click',function(e){
            e.preventDefault();
            console.log('clicked add new concept');
            $('.concept-listpanel-new').find('select.c-select').select2('destroy');
            var $newConceptItem = $('.concept-listrow-new').clone(true,true);
            //var itemreplace = $newConceptItem.html().replace(/000000/g,"000001");
            //console.log(itemreplace);
            $newConceptItem.insertBefore('.concept-listrow-new').removeClass('u-hidden concept-listrow-new').addClass('new_concept');
            
            var $newConceptPanel = $('.concept-listpanel-new').clone(true,true);
            
            //Get value of current new concepts (new concepts array indexed from 0)
            var new_concepts = parseInt($('#num_concepts_add').val());

            //Update IDs and Names on all fields
            $newConceptPanel.find('.details-section input, .details-section select, .size-section input, .size-section select').each(function(){
            var new_id = $(this).attr('id').replace('new',new_concepts).replace('concepts_existing','concepts_new');

              $(this)
                .attr('name', new_id)
                .attr('id', new_id);

              //check multiple
              var this_multiple = $(this).attr('multiple');
              console.log("is it?" + this_multiple);
              // attribute exists?
              if(typeof this_multiple !== 'undefined' && this_multiple !== false) {
                  //add []
                  console.log('multiple');
                  console.log($(this).attr('name'));
                  $(this).attr('name',$(this).attr('name')+'[]');
                  
              } 
              
              if($(this).hasClass('dp'))
              {
                $(this).addClass('datapicker');
              }

            });

            $newConceptPanel.find('.seatings-section input, .seatings-section select').each(function(){
              var new_id = $(this).attr('id').replace('concepts_existing[new]','concepts_new['+new_concepts+']');
  
                $(this)
                  .attr('id', new_id);

                if(!$(this).closest('.seating-container').hasClass('new_seating')){
                  $(this)
                  .attr('name', new_id);
                }

                  
  
              });

            $newConceptPanel.find('input.datapicker').each(function(index, element){
              
                console.log(element);
                var $el = $(element),
                  placeholder = $el.data('placeholder'),
                  displayFormat = $el.data('format'),
                  closeText = $el.data('close-text'),
                  availableDays = $el.data('available-days'),
                  yearChanged = false,
                  dateSelected = false,
                  valueFormat = 'yy-mm-dd',
                  clone;
      
                if (typeof displayFormat === 'undefined') {
                  displayFormat = 'mm.dd.yy';
                }
      
                if (typeof placeholder === 'undefined') {
                  placeholder = displayFormat;
                }
      
                /* Create a hidden clone, which will contain the actual value */
                clone = $el.clone();
                clone.insertAfter(this);
                clone.hide();
      
                /* Rename the original field, used to contain the display value */
                var id = $el.attr('id') ? $el.attr('id') : index;
                var name = $el.attr('name') ? $el.attr('name') : index;
      
                $el.attr('id', id + '-display');
                $el.attr('name', name + '-display');
                clone.attr('id', id);
                clone.attr('name', name);
      
                /* Create the datepicker with the desired display format and alt field */
                $el.datepicker({
                  dateFormat: displayFormat,
                  altField: window.escapeSelector('#' + id),
                  altFormat: valueFormat,
                  changeMonth: true,
                  changeYear: true,
                  showOn: 'focus',
                  showButtonPanel: true,
                  closeText: closeText, // Text to show for "close" button
                  beforeShowDay: enableAllTheseDays,
                  beforeShow: function (input, inst) {
                    // console.log('beforeShow');
                    var currentDate = new Date(clone.val()),
                      currentDay = currentDate.getDate(),
                      currentMonth = currentDate.getMonth() + 1,
                      currentYear = currentDate.getFullYear();
      
                    if (typeof availableDays === 'undefined') {
                      $el.datepicker('option', 'defaultDate', new Date(clone.val()));
      
                      // if ( 1 === day && 1 === month ) {
                      //   $el.datepicker( 'option', 'dateFormat', 'yy' );
                      // }
      
                      $el.datepicker('setDate', currentDate);
                      yearChanged = false;
                    }
                  },
                  onClose: function (dateText, inst) {
                    // console.log('onClose');
      
                    var event = arguments.callee.caller.caller.arguments[0],
                      $target = inst.input,
                      container_id = $target.data('container'),
                      $dashboard = $(container_id).find('.saved-box__item'),
                      delegateTarget = $(event.delegateTarget);
      
                    if (delegateTarget.hasClass('ui-datepicker-close')) {
                      var buttonText = $.trim(delegateTarget.text());
      
                      // If "Clear" gets clicked, then really clear it
                      if ('Clear' === buttonText) {
                        $el.val('');
                        clone.val('');
      
                        if (!$dashboard.length) {
                          return !1;
                        }
      
                        $dashboard.show();
                        return !1;
                      }
                    }
      
                    if (true === yearChanged) {
      
                      if (typeof availableDays === 'undefined') {
                        var year = $('#ui-datepicker-div .ui-datepicker-year :selected').val();
      
                        $el.datepicker('option', 'dateFormat', 'yy');
                        $el.datepicker('setDate', new Date(year, 0, 1));
                      }
      
                    } else {
      
                      if (true === dateSelected) { // if uncommented a lines bellow - bug on FireFix and Safari - auto-selected a current date
      
                        // $el.datepicker( 'option', 'dateFormat', displayFormat );
                        // $el.datepicker( 'setDate', new Date( dateText ) );
                      }
                    }
                  },
                  onChangeMonthYear: function (year, month, inst) {
                    var _month = month - 1;
      
                    // Skip setted only year on report and export pages.
                    if ($('body').hasClass('reporting') || $('body').hasClass('single-export')) {
                      return !1;
                    }
      
                    if (inst.currentYear !== year && _month === inst.currentMonth) {
                      yearChanged = true;
                    } else {
                      yearChanged = false;
                    }
                  },
                  onSelect: function (dateText, inst) {
                    // console.log('onSelect');
      
                    var $target = inst.input,
                      container_id = $target.data('container'),
                      filter = dateText,
                      $dashboard = $(container_id).find('.saved-box__item');
      
                    dateSelected = true;
                    yearChanged = false;
                    $el.datepicker('option', 'dateFormat', displayFormat);
      
                    if (!$dashboard.length) {
                      return !1;
                    }
      
                    $dashboard.each(function (i, element) {
                      var $element = $(element);
                      date = $element.data('post-date');
      
                      if ('-1' == filter) {
                        $element.show();
                        return;
                      }
      
                      if (filter === date) {
                        $element.show();
                      } else {
                        $element.hide();
                      }
      
                    });
                  }
                }).attr({
                  'readonly': 'readonly',
                  'placeholder': placeholder
                });
      
                var enableDaysObj = $el.data('available-days'),
                  enableDaysArray = (typeof enableDaysObj === 'object') ? Object.values(enableDaysObj) : false;
      
                function enableAllTheseDays(date) {
                  if (typeof enableDaysObj !== 'object') {
                    return [true];
                  }
      
                  var sdate = $.datepicker.formatDate(valueFormat, date);
      
                  if ($.inArray(sdate, enableDaysArray) != -1) {
                    return [true];
                  }
      
                  return [false];
                }
      
                $('.ui-datepicker-current').css('display', 'none');
      
                /* Finally, parse the value and change it to the display format */
                if ($el.attr('value')) {
                  var date = $.datepicker.parseDate(valueFormat, $el.attr('value')),
                    day = date.getDate(),
                    month = date.getMonth() + 1,
                    year = date.getFullYear(),
                    format = displayFormat;
      
                  if (1 === day && 1 === month) {
                    format = 'yy';
                  }
      
                  $el.attr('value', $.datepicker.formatDate(format, date));
                }
              
            });

            //Update For for all labels
            $newConceptPanel.find('label').each(function(){
              $(this)
                .prop('for',$(this).attr('for').replace('new',new_concepts).replace('concepts_existing','concepts_new'));
            });

            //list item / panel item id, data-concept
            //seating section, size section, details section ids, data-concept
            //span new total seats

            //var panelreplace = $newConceptPanel.html().replace(/000000/g,"000001");
            $newConceptPanel.insertAfter($newConceptItem).removeClass('concept-listpanel-new').addClass('new_concept');
            
            //Increment new concept counter
            $('#num_concepts_add').val(new_concepts+1);

            $('.concept-listpanel-new').find('select.c-select').select2({width: '100%'});
            $newConceptPanel.find('select').select2({width: '100%'});
            $newConceptItem.find('.js-concept-list-open').click();
            



            //window.fire_datepickers();
            //$newConceptPanel.find('select').select2();
            //$('.c-concept-list__add').hide();
          });

          $('.js-concept-list-delete').on('click',function(e){
            e.preventDefault();
            console.log('clicked delete new concept');
            
            var $this_panel = $(this).closest('.c-concept-list__panel');
            var $this_row = $this_panel.prev('.c-concept-list__row');
            $.when(window.ui_confirm("Are you sure?")).done(function (val) {
              // if the user clicked yes
              console.log('confirmed');
              if (val) {
                $this_panel.slideUp(400,function(){
                  $this_panel.find('select.c-select').select2('destroy');
                  $this_panel.find('input.datapicker').datepicker('destroy');
                  $this_row.remove();
                  $this_panel.remove();

                  $('#num_concepts_new').val(parseInt($('#num_concepts_new').val()) - 1);
                });
              }
            });

            //need to destroy datepickers and selects!
            //$(this).datepicker('destroy')
            //$('.concept-listpanel-new').find('select.c-select').select2('destroy')
          });
        }
      }
    };