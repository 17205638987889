export var dashboardSavedReports = {
    saved_reports: {
        init: function () {
  
            // $( 'body' ).on( 'touchmove', function( e ) {
            //   if ( $( '.hta-modal--opened' ).has( $( e.target ) ).length ) {
            //     e.preventDefault();
            //     e.stopPropagation();
            //     return false;
            //   }
            // } );
  
            $('.c-report-list__btn, .js-sd-preview-title').click(function (event) {
                var $container = $(this).closest('.c-report-list__item'),
                report_id = $container.data('report-id');
    
                //console.log('container and report');
                //console.log($container);
                //console.log(report_id);
                var modalOptions = {
                    modal: true,
                    resizable: false,
                    draggable: false,
                    height: 600,
                    minWidth: 770,
                    closeText: "",
                    classes: {
                        "ui-dialog": "modal-content",
                        "ui-dialog-titlebar": "modal-header",
                        "ui-dialog-title": "modal-title",
                        "ui-dialog-titlebar-close": "close",
                        "ui-dialog-content": "modal-body",
                        "ui-dialog-buttonpane": "modal-footer"
                    },
                    open: function (event, ui) {
                        $('body').addClass('hta-modal--opened');
                        // alert('disable_scroll');
                    },
                    beforeClose: function (event, ui) {
                        $('body').removeClass('hta-modal--opened');
                        // alert('enable_scroll');
                    }
                };
    
                var requestOptions = {
                    'action': "hta_load_report_modal",
                    '_ajax_nonce': window.data.nonce,
                    'id': report_id
                };
    
                var requestCallback = function (res) {
                    $('#report-modal').loadingModal('destroy');
                }
    
                event.preventDefault();
    
                $('#report-modal')
                    .html('')
                    .load(window.data.ajaxurl, requestOptions, requestCallback)
                    .dialog(modalOptions).dialog('open')
                    .loadingModal();
    
            });
    
            $('#report-modal').on('click', '.js-save-report-note', function (event) {
                var report_id = $(this).data('report-id'),
                    note = $('.js-report-note').val();
    
                $.ajax({
                    url: window.data.ajaxurl,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                            'action': 'hta_update_report_note',
                            '_ajax_nonce': window.data.nonce,
                            'report_id': report_id,
                            'note': note,
                        },
                })
                .done(function (res) {
                    if (res.success) {
                        $('.saved-box__item[data-report-id="' + report_id + '"]')
                            .find('.sd-preview__descr')
                            .html(res.data.trimmed_note);
                        window.show_message(res.data.message);
                    } else {
                        window.show_message(res.data, 'error');
                    }
    
                })
                .fail(function (res) {
                    window.show_message(res.data, 'error');
                })
    
            });
    
            $('#report-modal').on('click', '.js-sd-action', actionSDCallback);

            function actionSDCallback(event) {
                var $target = $(event.currentTarget),
                    action = $target.data('action'),
                    $embed = $('#rep_dashboard_pdf'),
                    pdfName = $embed.data('report-slug'),
                    pdfLink = $embed.attr('src');
    
                event.preventDefault();
    
                if (typeof (pdfLink) === 'undefined') {
                    return !1;
                }

                switch (action) {
                    case 'download':
                        var element = document.createElement('a');
        
                        element.setAttribute('href', pdfLink);
                        element.setAttribute('download', pdfName + '.pdf');
        
                        element.style.display = 'none';
                        document.body.appendChild(element);
        
                        element.click();
        
                        document.body.removeChild(element);
                    break;

                    case 'print':
                        window.printJS({
                            printable: pdfLink,
                            type: 'pdf'
                        });
                    break;

                    case 'email':
                        window.location.href = 'mailto:?subject=SAVED DASHBOARD&body=link - ' + pdfLink;
                    break;

                    default:
                    break;
                }
            }
        }
    } // end saved_reports
};