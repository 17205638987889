export var dashboardButtons = {
    buttons_action_events: {
        init: function () {
            // console.log( 'buttons_action_events: init' );
  
            $('.js-save-target').on('click', savePDFCallback);
          
            function savePDFCallback(event) {
                var $this = $(this);

                event.preventDefault();

                generatePDF($this, true, 'create');
            }
  
            function generatePDF($targetBtn, uploadToMedia, actionName) {
                var $body = $('body'),
                    $form = $($targetBtn.data('form-target')),
                    name = $('.dashboard__section_label:visible').text(),
                    delay = function (ms) {
                        return new Promise(function (r) {
                        setTimeout(r, ms);
                        })
                    },
                    time = 100,
                    options = {};
    
                $body.loadingModal();
    
                if (uploadToMedia === undefined) {
                    uploadToMedia = false;
                }
    
                options = {
                    url: window.data.ajaxurl,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        _ajax_nonce: window.data.nonce,
                        action: 'create_pdf',
                        new_attachment: uploadToMedia,
                        pdf: {
                            name: name,
                            base64: null
                        }
                    },
                    beforeSubmit: function (formData, jqForm, options) {
                        // console.log(formData);
                    },
                    success: function (response, statusText, xhr, $form) {
                        // console.log(response);
        
                        $body.loadingModal('destroy');
        
                        if (!response.hasOwnProperty('data')) {
                            return !1;
                        }
        
                        if (typeof (response.data) !== 'object') {
                            return !1;
                        }
        
                        if (!response.data.hasOwnProperty('pdf_url')) {
                            return !1;
                        }
    
                        switch (actionName) {
                            case 'create':
                                window.location = window.data.savedPeportsPageURL;
                            break;
        
                            case 'print':
                                window.printJS({
                                    printable: response.data.pdf_url,
                                    type: 'pdf'
                                });
                            break;
    
                            case 'download':
                                var element = document.createElement('a');
            
                                element.setAttribute('href', response.data.pdf_url);
                                element.setAttribute('download', name + '.pdf');
            
                                element.style.display = 'none';
                                document.body.appendChild(element);
            
                                element.click();
            
                                document.body.removeChild(element);
                            break;
                            default:
                            break;
                        }
    
                    }
                };
  
                $form.on('submit', function (e, additionalData) {
                    options.data.pdf.name = additionalData.name;
                    options.data.pdf.base64 = additionalData.base64;
                    //console.log('ajaxSubmit');
                    //console.log(options);
                    $(this).ajaxSubmit(options);
  
                    return false;
                });
  
                delay(time)
                .then(function () {
            
                    window.html2pdf($('.js-dashboard-content')[0], {
                        margin: [2, -13, 0, -13],
                        filename: 'temp.pdf',
                        image: { type: 'jpeg', quality: 1 },
                        enableLinks: false,
                        html2canvas: {
                            // dpi: 192,
                            scale: 2,
                            letterRendering: false,
                            logging: false,
                            width: 1500,
                            height: 1050, // 980
                            x: 0,
                            y: 0,
                            scrollX: 0,
                            scrollY: 0,
                            windowWidth: 1500,
                            windowHeight: 1050, // 980
                            onclone: function (document) {
                                //console.log('onclone');
            
                                $(document).find('.dashboard__panel').css('border', '1px solid #e5e5e5');
                                $(document).find('.dashboard__controls').remove();
            
                                if (!$('body').hasClass('dashboard')) {
                                    return !1;
                                }

                                var currentDate = new Date(),
                                    day = currentDate.getDate(),
                                    month = currentDate.getMonth() + 1,
                                    year = currentDate.getFullYear(),
                                    hours = currentDate.getHours(),
                                    minutes = currentDate.getMinutes();

                                //console.log(currentDate);

                                if (day < 10) {
                                    day = '0' + day;
                                }

                                if (month < 10) {
                                    month = '0' + month;
                                }

                                if (minutes < 10) {
                                    minutes = '0' + minutes;
                                }
            
                                name = name + ' ' + month + '.' + day + '.' + year + ' ' + hours + ':' + minutes;

                                $(document).find('.dashboard__section_label').text(name);
                                //console.log(name);
                            }
                        },
                        jsPDF: { unit: 'mm', format: 'letter', orientation: 'l' },
                        pdfCallback: function (pdf) {
                            $form.trigger('submit', {
                                name: name,
                                base64: pdf.output('datauristring')
                            });
        
                            $form.off('submit');
                        } // end pdfCallback
                    }); 
                }); // then callback
            }
  
            // `PDF` button handler.
            $('.js-pdf-target').on('click', downloadPDFCallback);
            
            function downloadPDFCallback(event) {
                var $currentTarget = $(event.currentTarget);
    
                event.preventDefault();
    
                generatePDF($currentTarget, false, 'download');
            }
  
            // `Print` button handler.
            $('.js-print-target').on('click', printPDFCallback);
            function printPDFCallback(event) {
                var $currentTarget = $(event.currentTarget);
    
                event.preventDefault();
    
                generatePDF($currentTarget, false, 'print');
            }
        }   //end init
    } // end buttons_action_events
};