import Mq from '../util/mq'

export default class BgSwap {
  constructor($el) {
    this.$el = $($el)

    const bg = this.$el.attr('data-bg')
    const bgM = this.$el.attr('data-bg-m')

    Mq.atLeast('--lg', e => {
      if (e.matches) {
        this.$el.css('background-image', `url(${bg})`)
      } else {
        this.$el.css('background-image', `url(${bgM})`)
      }
    })
  }
}
