export var dashboardReportHandler = {
    report_handler: {
        init: function () {
            console.log('Dashboard Report Handler Init');
            $(document).ready(function () {
                var link = $('#iframe_link').attr('data-iframe');
                if (link) {
                    $('iframe#result_frame').attr('src', link);
                }
  
                $('button#report_submit_delete').on('click', function () {
                    var report_id = $(this).data('report');
        
                    $('input#report_mode_' + report_id).val('delete');
                });
            });
  
            $('#report_submit_update').on('click', function () {
                var report_id = $(this).data('report');
    
                $('#report_mode_' + report_id).val('update');
            });
  
            $('.report_link').on('click', function () {
                var link = $(this).attr('data-href');
                $('iframe').removeClass('u-hidden').attr('src', link);
            });
        }
    } // end report_handler  
};